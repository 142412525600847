import React, {createContext, useEffect, useState} from "react";
import {getLocalStorageObject, randomString, setLocalStorageObject} from "../../misc/misc";


export interface ClientIdContextState {
    clientId: string | null
    setClientId: (clientId: string | null) => void
}

const initialState: ClientIdContextState = {
    clientId: null, setClientId: () => {
    }
}

export const CLIENT_ID_STORAGE_KEY = 'aiosClientId'
export const ClientIdContext = createContext<ClientIdContextState>(initialState)


const ClientIdProvider: React.FC = ({children}) => {
    const [clientId, setClientId] = useState<string | null>(getLocalStorageObject(CLIENT_ID_STORAGE_KEY))

    const defineClientId = (clientId: string | null) => {
        setClientId(clientId)
        setLocalStorageObject(CLIENT_ID_STORAGE_KEY, clientId)
    }

    useEffect(() => {
        if (!clientId) defineClientId(randomString())
    })

    return (
            <ClientIdContext.Provider value={{clientId, setClientId: defineClientId}}>
                {children}
            </ClientIdContext.Provider>
    )
}

export default ClientIdProvider

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingValueResponse
 */
export interface SettingValueResponse {
    /**
     * 
     * @type {string}
     * @memberof SettingValueResponse
     */
    value?: string;
}

export function SettingValueResponseFromJSON(json: any): SettingValueResponse {
    return SettingValueResponseFromJSONTyped(json, false);
}

export function SettingValueResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingValueResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function SettingValueResponseToJSON(value?: SettingValueResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
    };
}


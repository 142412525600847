import {CreateAdapter, FindAdapter, GetAdapter, Page, Pageable, UpdateAdapter, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {DeploymentResponse, DeploymentResponseFromJSON} from "../generated/models/DeploymentResponse";
import {CreateDeploymentRequest} from "../generated/models/CreateDeploymentRequest";
import {UpdateDeploymentRequest} from "../generated/models/UpdateDeploymentRequest";
import {DeploymentSpecification} from "../generated/models/DeploymentSpecification";
import {DeploymentEnvironment} from "../generated/models/DeploymentEnvironment";
import {DeploymentReachability} from "../generated/models/DeploymentReachability";
import {DeploymentImage} from "../generated/models/DeploymentImage";
import {DeploymentResources} from "../generated/models/DeploymentResources";
import {DeploymentLabel} from "../generated/models/DeploymentLabel";
import {DeploymentVariable} from "../generated/models/DeploymentVariable";
import {DeploymentFile} from "../generated/models/DeploymentFile";
import {DeploymentEndpoints} from "../generated/models/DeploymentEndpoints";
import {DeploymentReachabilitySecurity} from "../generated/models/DeploymentReachabilitySecurity";
import {DeploymentTelemetry} from "../generated/models/DeploymentTelemetry";
import {DeploymentEndpoint} from "../generated/models/DeploymentEndpoint";
import {DeploymentStatus, DeploymentStatusFromJSON} from "../generated/models/DeploymentStatus";

export class DeploymentAdapter implements CreateAdapter<UUID, DeploymentResponse>, UpdateAdapter<UUID, DeploymentResponse>, FindAdapter<UUID, DeploymentResponse>, GetAdapter<UUID, DeploymentResponse> {
    private readonly base: string = `/deployments`

    create(login: LoginHolder | null, elements: any[]): Promise<DeploymentResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateDeploymentRequest): Promise<DeploymentResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return DeploymentResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<DeploymentResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<DeploymentResponse>
                })
    }

    findVersions(login: LoginHolder | null, id: UUID, request: Pageable): Promise<Page<DeploymentResponse>> {
        return apiGet(login, `${this.base}/${id}/versions?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<DeploymentResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<DeploymentResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return DeploymentResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: UpdateDeploymentRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateDeploymentRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    status(login: LoginHolder | null, id: UUID): Promise<DeploymentStatus> {
        return apiGet(login, `${this.base}/${id}/status`)
                .then(response => {
                    console.debug("Retrieved status for {}", response)
                    return DeploymentStatusFromJSON(response)
                })
    }
}

export class DeploymentSpecificationImpl implements DeploymentSpecification {
    image: DeploymentImage = new DeploymentImageImpl();
    resources: DeploymentResources = new DeploymentResourcesImpl();
    environment: DeploymentEnvironment = new DeploymentEnvironmentImpl();
    endpoints: DeploymentEndpoints = new DeploymentEndpointsImpl();
    telemetry: DeploymentTelemetry = new DeploymentTelemetryImpl();
    reachability: DeploymentReachability = new DeploymentReachabilityImpl();
    tags: Array<string> = [];
}

export class DeploymentImageImpl implements DeploymentImage {
    name: string = "";
    tag: string = "";
    ports: Array<number> = [];
}

export class DeploymentResourcesImpl implements DeploymentResources {
    replicas: number = 1;
    cpuMaxInMilliseconds: number = 1;
    cpuMinInMilliseconds: number = 1;
    memoryMaxInMegaBytes: number = 1;
    memoryMinInMegaBytes: number = 1;
    gpu: number = 0;
    runRestrictions: Array<DeploymentLabel> = [];
}

export class DeploymentEnvironmentImpl implements DeploymentEnvironment {
    configFiles: Array<DeploymentFile> = [];
    variables: Array<DeploymentVariable> = [];
}

export class DeploymentEndpointsImpl implements DeploymentEndpoints {
    health: DeploymentEndpoint = {path: "/health", port: 8080};
    ready: DeploymentEndpoint = {path: "/health", port: 8080};
    started: DeploymentEndpoint = {path: "/health", port: 8080};
    openApi: DeploymentEndpoint = {path: "/openapi.json", port: 8080};
}

export class DeploymentTelemetryImpl implements DeploymentTelemetry {
    metricsEndpoint: DeploymentEndpoint = {path: "/metrics", port: 8080};
    metricsToRecordAsRegex: string[] = [];
}

export class DeploymentReachabilityImpl implements DeploymentReachability {
    isPublic: boolean = false;
    domain: string = "";
    domainUseTls: boolean = true;
    security: DeploymentReachabilitySecurity = new DeploymentReachabilitySecurityImpl();
}

export class DeploymentReachabilitySecurityImpl implements DeploymentReachabilitySecurity {
    useBasicAuth: boolean = false;
    basicAuthPassword: string = "";
    basicAuthUsername: string = "";
}

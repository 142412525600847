import {LoginUserRequest} from "../generated/models/LoginUserRequest";
import {LoginUserResponse} from "../generated/models/LoginUserResponse";
import {AuthAdapter} from "../adapters/AuthAdapter";
import {users} from "./demoData";
import {LoginHolder} from "../components/provider/LoginProvider";

export class AuthAdapterDemo extends AuthAdapter {

    login(element: LoginUserRequest): Promise<LoginUserResponse> {

        if (element.email === users.superAdmin.email && element.password === users.superAdmin.password)
            return Promise.resolve({userId: users.superAdmin.id, token: users.superAdmin.token})
        if (element.email === users.defaultAdmin.email && element.password === users.defaultAdmin.password)
            return Promise.resolve({userId: users.defaultAdmin.id, token: users.defaultAdmin.token})

        return Promise.reject("Invalid credentials")
    }

    logout(login: LoginHolder | null): Promise<any> {
        return Promise.resolve()
    }

}

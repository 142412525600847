import React, {createContext, useContext, useEffect, useState} from "react";
import {getLocalStorageObject, setLocalStorageObject} from "../../misc/misc";
import {LoginContext} from "./LoginProvider";
import {TenantResponse} from "../../generated/models/TenantResponse";
import {PageableRequest, tenantAdapter, userSettingAdapter} from "../../adapters/interfaces";
import {UserSettingDefinition} from "../../adapters/UserSettingAdapter";


export interface TenantHolder {
    tenant: TenantResponse
}

export interface TenantContextState {
    tenant: TenantHolder | null
    setTenant: (tenant: TenantHolder | null) => void
}

const initialState: TenantContextState = {
    tenant: null, setTenant: () => {
    }
}

export const OIDC_TENANT_STORAGE_KEY = 'aiosTenant'
export const TenantContext = createContext<TenantContextState>(initialState)


const TenantProvider: React.FC = ({children}) => {
    const {login} = useContext(LoginContext)
    const [tenant, setTenant] = useState<TenantHolder | null>(getLocalStorageObject(OIDC_TENANT_STORAGE_KEY))
    const [tenantSet, setTenantSet] = useState<boolean>(false)
    const defineTenant = (tenant: TenantHolder | null) => {
        setTenant(tenant)
        setLocalStorageObject(OIDC_TENANT_STORAGE_KEY, tenant)
    }
    useEffect(() => {
        if (!tenantSet && !tenant && login) {
            setTenantSet(true)
            tenantAdapter.find(login, new PageableRequest(0, 1000)).then((tenants) => {
                if (tenants && tenants.elements && tenants.elements.length > 0) {
                    const defaultTenantSettingName = UserSettingDefinition[UserSettingDefinition.DEFAULT_TENANT]
                    return userSettingAdapter.get(login, defaultTenantSettingName).then((settingResponse) => {
                        if (!settingResponse?.value) return Promise.reject('Value is null')
                        else defineTenant({tenant: tenants.elements.find(t => t.id === settingResponse.value)!})
                    }).catch(() => {
                        let tenant = tenants.elements.pop()!
                        console.debug(`Tenant ${tenant.name} set`)
                        defineTenant({tenant})
                    })
                } else {
                    console.debug(`No tenants found`)
                }
            })
        } else {
            console.debug(`Tenant already set`)
        }
    }, [login])
    return (
            <TenantContext.Provider value={{tenant, setTenant: defineTenant}}>
                {children}
            </TenantContext.Provider>
    )
}

export default TenantProvider

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRoleResponse,
    UserRoleResponseFromJSON,
    UserRoleResponseFromJSONTyped,
    UserRoleResponseToJSON,
} from './UserRoleResponse';

/**
 * 
 * @export
 * @interface UserRolesResponse
 */
export interface UserRolesResponse {
    /**
     * 
     * @type {Array<UserRoleResponse>}
     * @memberof UserRolesResponse
     */
    elements: Array<UserRoleResponse>;
}

export function UserRolesResponseFromJSON(json: any): UserRolesResponse {
    return UserRolesResponseFromJSONTyped(json, false);
}

export function UserRolesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRolesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(UserRoleResponseFromJSON)),
    };
}

export function UserRolesResponseToJSON(value?: UserRolesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(UserRoleResponseToJSON)),
    };
}


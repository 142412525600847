/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiKeyRoleResponse
 */
export interface ApiKeyRoleResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyRoleResponse
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyRoleResponse
     */
    apiKeyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyRoleResponse
     */
    roleId: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiKeyRoleResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiKeyRoleResponse
     */
    updatedAt: Date;
}

export function ApiKeyRoleResponseFromJSON(json: any): ApiKeyRoleResponse {
    return ApiKeyRoleResponseFromJSONTyped(json, false);
}

export function ApiKeyRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyRoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'],
        'apiKeyId': json['apiKeyId'],
        'roleId': json['roleId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ApiKeyRoleResponseToJSON(value?: ApiKeyRoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'apiKeyId': value.apiKeyId,
        'roleId': value.roleId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


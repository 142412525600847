import {LoginHolder} from "../components/provider/LoginProvider";
import {DeploymentResponse} from "../generated/models/DeploymentResponse";
import {CreateDeploymentRequest} from "../generated/models/CreateDeploymentRequest";
import {UpdateDeploymentRequest} from "../generated/models/UpdateDeploymentRequest";
import {DeploymentStatus} from "../generated/models/DeploymentStatus";
import {DeploymentAdapter} from "../adapters/DeploymentAdapter";
import {Page, Pageable, UUID} from "../adapters/interfaces";

export class DeploymentAdapterDemo extends DeploymentAdapter {

    create(login: LoginHolder | null, elements: any[]): Promise<DeploymentResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateDeploymentRequest): Promise<DeploymentResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<DeploymentResponse>> {
        return Promise.reject("Not implemented")
    }

    findVersions(login: LoginHolder | null, id: UUID, request: Pageable): Promise<Page<DeploymentResponse>> {
        return Promise.reject("Not implemented")
    }

    get(login: LoginHolder | null, id: UUID): Promise<DeploymentResponse | null> {
        return Promise.reject("Not implemented")
    }

    update(login: LoginHolder | null, elements: UpdateDeploymentRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateDeploymentRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

    status(login: LoginHolder | null, id: UUID): Promise<DeploymentStatus> {
        return Promise.reject("Not implemented")
    }
}

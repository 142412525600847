/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react';
import Layout from "./src/components/layout/Layout";
import ThemeTypeProvider from "./src/components/provider/ThemeTypeProvider";
import {StyledEngineProvider} from "@mui/material/styles";
import UiConfigProvider from "./src/components/provider/UiConfigProvider";
import KeycloakProvider from "./src/components/provider/KeycloakProvider";
import LoginProvider from "./src/components/provider/LoginProvider";
import OidcTokenProvider from "./src/components/provider/OidcTokenProvider";
import TenantProvider from "./src/components/provider/TenantProvider";
import ClientIdProvider from "./src/components/provider/ClientIdProvider";

export const wrapRootElement = ({element}) => {
    return <StyledEngineProvider injectFirst>
        <ThemeTypeProvider>
            <ClientIdProvider>
                <UiConfigProvider>
                    <LoginProvider>
                        <OidcTokenProvider>
                            <KeycloakProvider>
                                <TenantProvider>
                                    <Layout>{element}</Layout>
                                </TenantProvider>
                            </KeycloakProvider>
                        </OidcTokenProvider>
                    </LoginProvider>
                </UiConfigProvider>
            </ClientIdProvider>
        </ThemeTypeProvider>
    </StyledEngineProvider>;
};

import React, {createContext, useEffect, useState} from "react";
import {logger} from "../../misc/Logger";
import {uiConfigAdapter} from "../../adapters/interfaces";

export interface UiConfig {
    version: string
    loggingEnabled: boolean
    rootUrl: string
    openApiSpecUrl: string
    keycloakEnabled: boolean
    keycloakAuthServerUrl: string
    keycloakRealm: string
    keycloakResource: string
    keycloakOpenIdConnectUrl: string
    mqttEnabled: boolean
    mqttUrl: string
    grafanaEnabled: boolean
    grafanaUrl: string
}

export interface UiConfigContextState {
    uiConfig: UiConfig | null
    setUiConfig: (uiConfig: UiConfig | null) => void
}

const initialState: UiConfigContextState = {
    uiConfig: null, setUiConfig: () => {
    }
}

export const UiConfigContext = createContext<UiConfigContextState>(initialState)

const UiConfigProvider: React.FC = ({children}) => {
    const [uiConfig, setUiConfig] = useState<UiConfig | null>(null)
    useEffect(() => {
        if (!uiConfig) {
            uiConfigAdapter.config().then(response => {
                let isLoggingEnabled = response["logging-enabled"] === "true"
                let queryStringParams = new URLSearchParams(window.location.search)
                if (queryStringParams.has("loggingEnabled")) {
                    isLoggingEnabled = queryStringParams.get("loggingEnabled") === "true"
                }

                if (isLoggingEnabled) logger.enable()
                else logger.disable()

                console.debug("Loaded config")
                setUiConfig({
                    version: response["version"],
                    loggingEnabled: isLoggingEnabled,
                    rootUrl: response["root-url"],
                    openApiSpecUrl: response["open-api-spec-url"],
                    keycloakEnabled: response["keycloak-enabled"] === "true",
                    keycloakRealm: response["keycloak-realm"],
                    keycloakResource: response["keycloak-resource"],
                    keycloakAuthServerUrl: response["keycloak-auth-server-url"],
                    keycloakOpenIdConnectUrl: response["keycloak-openid-connect-url"],
                    mqttEnabled: response["mqtt-enabled"] === "true",
                    mqttUrl: response["mqtt-url"],
                    grafanaEnabled: response["grafana-enabled"] === "true",
                    grafanaUrl: response["grafana-url"],
                })
            })
        }
    })
    return (
            <UiConfigContext.Provider value={{uiConfig, setUiConfig}}>
                {children}
            </UiConfigContext.Provider>
    )
}

export default UiConfigProvider

import React, {createContext, useContext, useEffect, useState} from "react";
import {PermissionHolder} from "../../misc/authorization";
import useCookie from 'react-use-cookie';
import {UiConfigContext} from "./UiConfigProvider";

export interface LoginHolder {
    id: string
    email: string
    firstname: string | null
    lastname: string | null
    expiresAt: number
    token: string
    permissionsByTenant: PermissionHolder
    isInternal: boolean // if the login is from a native user which is stored in the application's database
}

export interface LoginContextState {
    login: LoginHolder | null
    setLogin: (login: LoginHolder | null) => void
}

const initialState: LoginContextState = {
    login: null, setLogin: () => {
    }
}

export const LOGIN_STORAGE_KEY = 'aiosLogin'
export const LoginContext = createContext<LoginContextState>(initialState)

const LoginProvider: React.FC = ({children}) => {
    const {uiConfig} = useContext(UiConfigContext)
    const [loginCookie, setLoginCookie] = useCookie(LOGIN_STORAGE_KEY)
    const [login, setLogin] = useState<LoginHolder | null>(loginCookie ? JSON.parse(loginCookie) as LoginHolder : null)

    useEffect(() => {
        if (uiConfig) {
            const host = new URL(uiConfig.rootUrl.startsWith("//") ? `${location.protocol}${uiConfig.rootUrl.replace("//:", "")}` : uiConfig.rootUrl).host
            const domain = host.split(":")[0]
            console.debug(`Using domain ${domain} for login cookie`)
            // Allowing cookie to be set for the current domain by omitting it for the demo.
            setLoginCookie(login ? JSON.stringify(login) : "", {
                days: 365,
                SameSite: "Strict",
            })
        }
    }, [login, uiConfig])

    return (
            <LoginContext.Provider value={{login, setLogin}}>
                {children}
            </LoginContext.Provider>
    )
}

export default LoginProvider

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeploymentReachabilitySecurity
 */
export interface DeploymentReachabilitySecurity {
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentReachabilitySecurity
     */
    useBasicAuth: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeploymentReachabilitySecurity
     */
    basicAuthUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentReachabilitySecurity
     */
    basicAuthPassword?: string;
}

export function DeploymentReachabilitySecurityFromJSON(json: any): DeploymentReachabilitySecurity {
    return DeploymentReachabilitySecurityFromJSONTyped(json, false);
}

export function DeploymentReachabilitySecurityFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentReachabilitySecurity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useBasicAuth': json['useBasicAuth'],
        'basicAuthUsername': !exists(json, 'basicAuthUsername') ? undefined : json['basicAuthUsername'],
        'basicAuthPassword': !exists(json, 'basicAuthPassword') ? undefined : json['basicAuthPassword'],
    };
}

export function DeploymentReachabilitySecurityToJSON(value?: DeploymentReachabilitySecurity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'useBasicAuth': value.useBasicAuth,
        'basicAuthUsername': value.basicAuthUsername,
        'basicAuthPassword': value.basicAuthPassword,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalPages: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Page
     */
    elements: Array<object>;
}

export function PageFromJSON(json: any): Page {
    return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Page {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'elements': json['elements'],
    };
}

export function PageToJSON(value?: Page | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'size': value.size,
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'elements': value.elements,
    };
}


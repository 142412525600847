/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserMetricsRangeResponseMetrics,
    UserMetricsRangeResponseMetricsFromJSON,
    UserMetricsRangeResponseMetricsFromJSONTyped,
    UserMetricsRangeResponseMetricsToJSON,
} from './UserMetricsRangeResponseMetrics';

/**
 * 
 * @export
 * @interface UserMetricsRangeResponse
 */
export interface UserMetricsRangeResponse {
    /**
     * 
     * @type {Array<UserMetricsRangeResponseMetrics>}
     * @memberof UserMetricsRangeResponse
     */
    metrics: Array<UserMetricsRangeResponseMetrics>;
}

export function UserMetricsRangeResponseFromJSON(json: any): UserMetricsRangeResponse {
    return UserMetricsRangeResponseFromJSONTyped(json, false);
}

export function UserMetricsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMetricsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': ((json['metrics'] as Array<any>).map(UserMetricsRangeResponseMetricsFromJSON)),
    };
}

export function UserMetricsRangeResponseToJSON(value?: UserMetricsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': ((value.metrics as Array<any>).map(UserMetricsRangeResponseMetricsToJSON)),
    };
}


import {Page, Pageable, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {AbstractAdapter} from "./AbstractAdapter";
import {ApiKeyResponse, ApiKeyResponseFromJSON} from "../generated/models/ApiKeyResponse";
import {CreateApiKeyRequest} from "../generated/models/CreateApiKeyRequest";
import {UpdateApiKeyRequest} from "../generated/models/UpdateApiKeyRequest";

export class ApiKeyAdapter extends AbstractAdapter<UUID, ApiKeyResponse> {
    private readonly base: string = `/api-keys`

    protected basePath(): string {
        return this.base;
    }

    create(login: LoginHolder | null, elements: any[]): Promise<ApiKeyResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateApiKeyRequest): Promise<ApiKeyResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return ApiKeyResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<ApiKeyResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<ApiKeyResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<ApiKeyResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return ApiKeyResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: UpdateApiKeyRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateApiKeyRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

}

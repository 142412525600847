export class Logger {
    consoleLog: any = null
    consoleInfo: any = null
    consoleDebug: any = null

    enable(): void {
        console.info("Logging enabled")
        if (this.consoleLog == null) return
        window["console"]["log"] = this.consoleLog
        if (this.consoleInfo == null) return
        window["console"]["info"] = this.consoleInfo
        if (this.consoleDebug == null) return
        window["console"]["debug"] = this.consoleDebug
    }

    disable(): void {
        console.info("Logging disabled")
        this.consoleLog = console.log
        window["console"]["log"] = function () {
        }
        this.consoleInfo = console.info
        window["console"]["info"] = function () {
        }
        this.consoleDebug = console.debug
        window["console"]["debug"] = function () {
        }
    }
}

export const logger = new Logger()

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentEndpoint,
    DeploymentEndpointFromJSON,
    DeploymentEndpointFromJSONTyped,
    DeploymentEndpointToJSON,
} from './DeploymentEndpoint';

/**
 * 
 * @export
 * @interface DeploymentTelemetry
 */
export interface DeploymentTelemetry {
    /**
     * 
     * @type {DeploymentEndpoint}
     * @memberof DeploymentTelemetry
     */
    metricsEndpoint: DeploymentEndpoint;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeploymentTelemetry
     */
    metricsToRecordAsRegex: Array<string>;
}

export function DeploymentTelemetryFromJSON(json: any): DeploymentTelemetry {
    return DeploymentTelemetryFromJSONTyped(json, false);
}

export function DeploymentTelemetryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentTelemetry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metricsEndpoint': DeploymentEndpointFromJSON(json['metricsEndpoint']),
        'metricsToRecordAsRegex': json['metricsToRecordAsRegex'],
    };
}

export function DeploymentTelemetryToJSON(value?: DeploymentTelemetry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metricsEndpoint': DeploymentEndpointToJSON(value.metricsEndpoint),
        'metricsToRecordAsRegex': value.metricsToRecordAsRegex,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TenantUserResponse,
    TenantUserResponseFromJSON,
    TenantUserResponseFromJSONTyped,
    TenantUserResponseToJSON,
} from './TenantUserResponse';

/**
 * 
 * @export
 * @interface TenantUsersResponse
 */
export interface TenantUsersResponse {
    /**
     * 
     * @type {Array<TenantUserResponse>}
     * @memberof TenantUsersResponse
     */
    elements: Array<TenantUserResponse>;
}

export function TenantUsersResponseFromJSON(json: any): TenantUsersResponse {
    return TenantUsersResponseFromJSONTyped(json, false);
}

export function TenantUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(TenantUserResponseFromJSON)),
    };
}

export function TenantUsersResponseToJSON(value?: TenantUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(TenantUserResponseToJSON)),
    };
}


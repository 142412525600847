import React, {createContext, useContext, useState} from "react";
import {setupLogin} from "../../misc/authentication";
import {LoginContext} from "./LoginProvider";
import {OidcTokenContext} from "./OidcTokenProvider";

export interface KeycloakContext {
    isInitialized: boolean
    keycloak: any
}

export interface KeycloakContextState {
    keycloak: KeycloakContext | null
    setKeycloak: (keycloak: KeycloakContext | null) => void
}

const initialState: KeycloakContextState = {
    keycloak: null, setKeycloak: () => {
    }
}

export const KeycloakContext = createContext<KeycloakContextState>(initialState)

const KeycloakProvider: React.FC = ({children}) => {
    const {setLogin} = useContext(LoginContext)
    const {setOidcToken} = useContext(OidcTokenContext)
    const [keycloak, setKeycloak] = useState<KeycloakContext | null>(null)
    const [isRefreshSetup, setRefreshSetup] = useState<boolean>(false)

    const defineKeycloak = (instance: KeycloakContext | null) => {
        setKeycloak(instance)
        if (instance != null && instance.isInitialized && !isRefreshSetup) {
            // automatically refresh token if the token expiration falls below the given threshold
            setInterval(() => {
                instance.keycloak.updateToken(120).then((refreshed: boolean) => {
                    if (refreshed) {
                        console.debug(`Token refreshed`)
                        setOidcToken({
                            token: instance.keycloak.token!,
                            refreshToken: instance.keycloak.refreshToken || null,
                            idToken: instance.keycloak.idToken || null,
                        })
                        setupLogin(instance.keycloak.token!, false, setLogin)
                                .then(() => console.debug(`Updated login after token refresh`))
                    } else {
                        console.debug(`Token not refreshed; valid for ${Math.round((instance.keycloak.tokenParsed?.exp || 0) + (instance.keycloak.timeSkew || 0) - (new Date().getTime() / 1000))} seconds`)
                    }
                }).catch((error: string) => console.error(`Failed to refresh token: ${error}`))
            }, 10000)
            setRefreshSetup(true)
        }
    }

    return (
            <KeycloakContext.Provider value={{keycloak, setKeycloak: defineKeycloak}}>
                {children}
            </KeycloakContext.Provider>
    )
}

export default KeycloakProvider

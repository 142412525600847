import {Page, Pageable, PageResponse, UUID} from "../adapters/interfaces";
import {UserResponse} from "../generated/models/UserResponse";
import {LoginHolder} from "../components/provider/LoginProvider";
import {UpdateUserRequest} from "../generated/models/UpdateUserRequest";
import {InviteUserRequest} from "../generated/models/InviteUserRequest";
import {CreateOneUserRequest} from "../generated/models/CreateOneUserRequest";
import {UserRoleResponse} from "../generated/models/UserRoleResponse";
import {CreateTenantUsersRequest} from "../generated/models/CreateTenantUsersRequest";
import {TenantUsersResponse} from "../generated/models/TenantUsersResponse";
import {DeleteTenantUsersRequest} from "../generated/models/DeleteTenantUsersRequest";
import {CreateUserRolesRequest} from "../generated/models/CreateUserRolesRequest";
import {DeleteUserRolesRequest} from "../generated/models/DeleteUserRolesRequest";
import {UserRolesResponse} from "../generated/models/UserRolesResponse";
import {TenantUserResponse} from "../generated/models/TenantUserResponse";
import {UserNameResponse} from "../generated/models/UserNameResponse";
import {UserAdapter} from "../adapters/UserAdapter";
import {tenants, users} from "./demoData";

export class UserAdapterDemo extends UserAdapter {

    invite(login: LoginHolder | null, element: InviteUserRequest): Promise<UserResponse> {
        return Promise.reject("Not implemented")
    }

    create(login: LoginHolder | null, elements: any[]): Promise<UserResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateOneUserRequest): Promise<UserResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<UserResponse>> {
        return Promise.reject("Not implemented")
    }

    get(login: LoginHolder | null, id: UUID): Promise<UserResponse | null> {
        return Promise.reject("Not implemented")
    }

    getName(login: LoginHolder | null, id: UUID): Promise<UserNameResponse | null> {
        return Promise.reject("Not implemented")
    }

    update(login: LoginHolder | null, elements: UpdateUserRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateUserRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

    //<editor-fold desc="Tenants">
    addTenants(login: LoginHolder | null, request: CreateTenantUsersRequest): Promise<TenantUsersResponse> {
        return Promise.reject("Not implemented")
    }

    removeTenants(login: LoginHolder | null, request: DeleteTenantUsersRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

    findTenants(login: LoginHolder | null, request: Pageable): Promise<Page<TenantUserResponse>> {
        return Promise.reject("Not implemented")
    }

    //</editor-fold>

    //<editor-fold desc="Roles">
    addRoles(login: LoginHolder | null, request: CreateUserRolesRequest): Promise<UserRolesResponse> {
        return Promise.reject("Not implemented")
    }

    removeRoles(login: LoginHolder | null, request: DeleteUserRolesRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

    findRoles(login: LoginHolder | null, request: Pageable): Promise<Page<UserRoleResponse>> {
        const user = Object.values(users).find(v => v.id === login?.id)
        const page = new PageResponse<UserRoleResponse>(request.number || 0, request.size || 0, user.roles.size, user.roles.map((r: any) => {
            return {
                tenantId: tenants.super.id,
                userId: user.id,
                roleId: r.id,
                roleName: r.name,
                permissions: r.permissions,
                createdAt: new Date(0),
                updatedAt: new Date(0),
            }
        }))
        return Promise.resolve(page)
    }

    //</editor-fold>

}

export const tenants: Record<string, any> = {
    super: {id: "00000000-0000-0000-0000-000000000000", name: "Super", alias: "super"},
    default: {id: "11111111-1111-1111-1111-111111111111", name: "Default", alias: "default"},
}
export const roles: Record<string, any> = {
    superAdminRole: {id: "0fffffff-ffff-ffff-ffff-ffffffffffff", name: "SUPER_ADMIN", permissions: ["*:*"]},
    defaultTenantAdminRole: {
        id: "1fffffff-ffff-ffff-ffff-ffffffffffff",
        name: "ADMIN_11111111-1111-1111-1111-111111111111",
        permissions: ["tenant:*:11111111-1111-1111-1111-111111111111", "user,apikey,deployment:*"],
    },
}
export const users: Record<string, any> = {
    superAdmin: {
        id: "00000000-0000-0000-0000-000000000000",
        email: "super.admin@local",
        password: "3bPvSScyTThUegEb",
        roles: [roles.superAdminRole, roles.defaultTenantAdminRole],
        token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJpc3MiOiJBSU9TIiwiZXhwIjo5NjYzMzYxMjUwLCJnaXZlbl9uYW1lIjoiU3VwZXIiLCJpYXQiOjE2NjMzMzI0NTAsImZhbWlseV9uYW1lIjoiQWRtaW4iLCJqdGkiOiIxZmZmZmZmZi1mZmZmLWZmZmYtZmZmZi1mZmZmZmZmZmZmZmYiLCJlbWFpbCI6InN1cGVyLmFkbWluQGxvY2FsIiwidXNlcm5hbWUiOiJzdXBlci5hZG1pbkBsb2NhbCJ9.ELtMrm3uAhcyg7k1zm7NJInaraWqI-PGjToz7HfPfqoNbcjhEYvHDdybprL2kFIQ7V0TLwVb7RhtRY439l9_6SF98hicF-aHDjrlRJmIjzyoaiK3_6b5EBkKJ95aJySEa4YPELSAXo_cy4QiEmqOm8PwknH1Vq32X2wtlZi6s6dT0E66GGLFsOtJJ2hwKkXQalMMQWX9503MXUfYxu3Cy8-Yx8ZxfVEPSlMvoJPjUsJQaAz35KRrSzzeIs-rtVDUJY27A3oEOAr-qyh7NG1XT6P1TBsS2QnucpoaWbx2LrqjcTQ5lBW10_SOVWfhUJMinmIq_KQvzESyIV3XjY2GaA",
    },
    defaultAdmin: {
        id: "11111111-1111-1111-1111-111111111111",
        email: "default.admin@local",
        password: "yQ0jlBJ3z8DloG6U",
        roles: [roles.defaultTenantAdminRole],
        token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMTExMTExMS0xMTExLTExMTEtMTExMS0xMTExMTExMTExMTEiLCJpc3MiOiJBSU9TIiwiZXhwIjo5NjYzMzYxMjUwLCJnaXZlbl9uYW1lIjoiRGVmYXVsdCIsImlhdCI6MTY2MzMzMjQ1MCwiZmFtaWx5X25hbWUiOiJBZG1pbiIsImp0aSI6IjJmZmZmZmZmLWZmZmYtZmZmZi1mZmZmLWZmZmZmZmZmZmZmZiIsImVtYWlsIjoiZGVmYXVsdC5hZG1pbkBsb2NhbCIsInVzZXJuYW1lIjoiZGVmYXVsdC5hZG1pbkBsb2NhbCJ9.U6M-M4BSo7pqEB032HIs5ODLIZ4W0iGOPUmJmJAvo9TlTon9ohoJRULpntpPd4phHKYDKD9I5ff8zMnK-LZQf_bdDiB_8iqDXdhf0X9O5ZOnFfHfDLQxcymUJ-OA8fX-whvUj7vrhH48pTgllDbQVZSKYgVr9Z_-c6wBC9DXfySWNqreUVP_G2CotMAPZkhsyiKpcBkrPN3zYHsQ9vbOasGu2qKSc1Z5R91o4QQV_eBeOerGLB-CqnSdVKESQZFLNPa1IpaXabw85pyWGaDAkhB27jlugh5sBtIOnzDJvpp4doeNhmM-X9el4pyDTvwGjGcyALLTImB5mjssdoM9JA",
    },
}

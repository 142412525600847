/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResourceMetricsRangeResponseMetrics,
    ResourceMetricsRangeResponseMetricsFromJSON,
    ResourceMetricsRangeResponseMetricsFromJSONTyped,
    ResourceMetricsRangeResponseMetricsToJSON,
} from './ResourceMetricsRangeResponseMetrics';

/**
 * 
 * @export
 * @interface ResourceMetricsRangeResponse
 */
export interface ResourceMetricsRangeResponse {
    /**
     * 
     * @type {Array<ResourceMetricsRangeResponseMetrics>}
     * @memberof ResourceMetricsRangeResponse
     */
    metrics: Array<ResourceMetricsRangeResponseMetrics>;
}

export function ResourceMetricsRangeResponseFromJSON(json: any): ResourceMetricsRangeResponse {
    return ResourceMetricsRangeResponseFromJSONTyped(json, false);
}

export function ResourceMetricsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceMetricsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': ((json['metrics'] as Array<any>).map(ResourceMetricsRangeResponseMetricsFromJSON)),
    };
}

export function ResourceMetricsRangeResponseToJSON(value?: ResourceMetricsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': ((value.metrics as Array<any>).map(ResourceMetricsRangeResponseMetricsToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    external?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    state?: UserResponseStateEnum;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    dashboardUserId?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    updatedAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum UserResponseStateEnum {
    Invited = 'INVITED',
    Active = 'ACTIVE'
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'external': !exists(json, 'external') ? undefined : json['external'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'dashboardUserId': !exists(json, 'dashboardUserId') ? undefined : json['dashboardUserId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function UserResponseToJSON(value?: UserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'external': value.external,
        'state': value.state,
        'dashboardUserId': value.dashboardUserId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


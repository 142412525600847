exports.components = {
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-costs-tsx": () => import("./../../../src/pages/costs.tsx" /* webpackChunkName: "component---src-pages-costs-tsx" */),
  "component---src-pages-customer-tsx": () => import("./../../../src/pages/customer.tsx" /* webpackChunkName: "component---src-pages-customer-tsx" */),
  "component---src-pages-extensions-tsx": () => import("./../../../src/pages/extensions.tsx" /* webpackChunkName: "component---src-pages-extensions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */)
}


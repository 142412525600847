import {ErrorResponse} from "./authentication";
import {navigate} from "gatsby";
import {LoginHolder} from "../components/provider/LoginProvider";

interface Headers {
    [key: string]: any
}

export function apiGet(login: LoginHolder | null, url: string, additionalHeaders: any = {}, addAuthorization: boolean = true): Promise<any> {
    return api(login, "GET", url, null, null, additionalHeaders, addAuthorization)
}

export function apiPost(login: LoginHolder | null,
                        url: string,
                        payload: any | null = null,
                        additionalHeaders: any = {},
                        addAuthorization: boolean = true): Promise<any> {
    return api(login, "POST", url, "application/json", payload, additionalHeaders, addAuthorization)
}

export function apiPut(login: LoginHolder | null,
                       url: string,
                       payload: any | null = null,
                       additionalHeaders: any = {},
                       addAuthorization: boolean = true): Promise<any> {
    return api(login, "PUT", url, "application/json", payload, additionalHeaders, addAuthorization)
}

export function apiDelete(login: LoginHolder | null,
                          url: string,
                          payload: any | null = null,
                          additionalHeaders: any = {},
                          addAuthorization: boolean = true): Promise<any> {
    return api(login, "DELETE", url, payload ? "application/json" : null, payload, additionalHeaders, addAuthorization)
}

export function api(login: LoginHolder | null,
                    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
                    url: string,
                    contentType: string | null = null,
                    payload: any | null = null,
                    additionalHeaders: any = {},
                    addAuthorization: boolean = true,
                    baseUrl: string = process.env.GATSBY_API_URL || ''): Promise<any> {
    console.debug(`Fetching ${baseUrl}${url} with ${method}`)
    let headers: Headers = {}
    if (addAuthorization) {
        if (!login) {
            navigate(`/app/login?url=${location.href}`)
            return Promise.reject("Login not found")
        }
        if (!login.token) {
            navigate(`/app/login?url=${location.href}`)
            return Promise.reject("Token in login not found")
        }
        headers["Authorization"] = `Bearer ${login.token}`
    }
    if (contentType) headers["Content-Type"] = contentType
    if (Object.keys(additionalHeaders).length) {
        headers = {...headers, ...additionalHeaders}
    }

    return fetch(`${baseUrl}${url}`, {
        method: method,
        headers: headers,
        body: payload ? JSON.stringify(payload) : null,
    })
            .then(extractResponse)
            .catch(error => {
                console.error(error.hasOwnProperty("message") ? error.message : error.toString(), error)
                return Promise.reject(error)
            })
}

export async function extractResponse(response: Response) {
    const isJson = response.headers.get("Content-Type")?.includes("application/json");
    if (isJson) {
        const data = await response.json()
        if (!response.ok) {
            if (data.hasOwnProperty("message")) {
                const error = (data && data as ErrorResponse) || {
                    message: response.statusText,
                    type: response.status.toString()
                } as ErrorResponse;
                return Promise.reject(error);
            }
            return Promise.reject(data)
        }
        return data
    } else {
        const data = await response.text()
        if (!response.ok) {
            const error = {
                message: data,
                type: response.status.toString()
            } as ErrorResponse;
            return Promise.reject(error)
        }
        return {payload: data}
    }
}

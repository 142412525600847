import {Page, Pageable, UUID} from "../adapters/interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {ApiKeyResponse} from "../generated/models/ApiKeyResponse";
import {CreateApiKeyRequest} from "../generated/models/CreateApiKeyRequest";
import {UpdateApiKeyRequest} from "../generated/models/UpdateApiKeyRequest";
import {ApiKeyAdapter} from "../adapters/ApiKeyAdapter";

export class ApiKeyAdapterDemo extends ApiKeyAdapter {

    create(login: LoginHolder | null, elements: any[]): Promise<ApiKeyResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateApiKeyRequest): Promise<ApiKeyResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<ApiKeyResponse>> {
        return Promise.reject("Not implemented")
    }

    get(login: LoginHolder | null, id: UUID): Promise<ApiKeyResponse | null> {
        return Promise.reject("Not implemented")
    }

    update(login: LoginHolder | null, elements: UpdateApiKeyRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateApiKeyRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

}

import {LoginHolder} from "../components/provider/LoginProvider";

export const SUPER_TENANT_ID = "00000000-0000-0000-0000-000000000000"

export enum PermissionEntity {
    ANY = "*",
    SETTING = "setting",
    TENANT = "tenant",
    USER = "user",
    APIKEY = "apikey",
    DEPLOYMENT = "deployment",
}

export enum PermissionAction {
    READ = "read",
    WRITE = "write",
    ALL = "*",
}

export interface Permission {
    entities: PermissionEntity[]
    actions: PermissionAction[]
    ids: string[]
}

export class WildcardPermission implements Permission {
    entities: PermissionEntity[] = []
    actions: PermissionAction[] = []
    ids: string[] = []

    constructor(shiroPermission: string) {
        const parts: string[][] = []
        const splitParts = shiroPermission.split(":")
        if (splitParts.length > 3) {
            console.error(`Invalid permission ${shiroPermission}`)
            return
        }
        for (const partKey in splitParts) {
            const subParts = splitParts[partKey].split(",")
            if (subParts.length === 0) {
                console.error(`Invalid permission ${shiroPermission}`)
                break
            }
            parts.push(subParts)
        }
        this.entities = parts[0].filter(entity => {
            if (Object.values(PermissionEntity).some((entityValue: string) => entityValue === entity)) {
                return true
            } else {
                console.error(`Ignoring unknown entity ${entity}`)
                return false
            }
        }).map(entity => <PermissionEntity>entity)
        if (parts.length > 1) {
            this.actions = parts[1].filter(action => {
                if (Object.values(PermissionAction).some((actionValue: string) => actionValue === action)) {
                    return true
                } else {
                    console.error(`Ignoring unknown action ${action}`)
                    return false
                }
            }).map(action => <PermissionAction>action)
        }
        if (parts.length > 2) {
            this.ids = parts[2]
        }
    }
}

export const permission: (entities: PermissionEntity[], actions: PermissionAction[], ids: string[]) => Permission = (entities: PermissionEntity[], actions: PermissionAction[], ids: string[]) => {
    const entitiesAsString = entities.join(",");
    if (actions.length === 0 && ids.length === 0) return new WildcardPermission(entitiesAsString)
    const actionsAsString = actions.join(",");
    if (ids.length === 0) return new WildcardPermission(`${entitiesAsString}:${actionsAsString}`)
    const idsAsString = ids.join(",");
    return new WildcardPermission(`${entitiesAsString}:${actionsAsString}:${idsAsString}`)
}

/**
 * This implementation is inspired by Apache Shiro's implementation in the org.apache.shiro.authz.permission.WildcardPermission class.
 */
export function implies(permission1: Permission, permission2: Permission): boolean {
    if (!permission1.entities.includes(PermissionEntity.ANY) && (permission2.entities.length === 0 || !permission2.entities.every(it => permission1.entities.includes(it)))) {
        return false
    }
    if (permission1.actions.length === 0) return true
    else if (!permission1.actions.includes(PermissionAction.ALL) && (permission2.actions.length === 0 || !permission2.actions.every(it => permission1.actions.includes(it)))) {
        return false
    }
    if (permission1.ids.length === 0) return true
    // noinspection RedundantIfStatementJS
    else if (!permission1.ids.includes("*") && (permission2.ids.length === 0 || !permission2.ids.every(it => permission1.ids.includes(it)))) {
        return false
    }
    return true
}

export interface PermissionHolder extends Record<string, Permission[]> {
}

export function permits(permissions: Permission[], login: LoginHolder | null, tenantId: string | null | undefined): boolean {
    if (permissions.length === 0) return false
    if (!login) return false
    if (!tenantId) return false
    return permissions.every(required => login.permissionsByTenant[tenantId]?.some(actual => implies(actual, required))) || false
}

export function permitsSuper(login: LoginHolder | null): boolean {
    return permits([permission([PermissionEntity.ANY], [PermissionAction.ALL], [])], login, SUPER_TENANT_ID)
}

export function permitsWithoutIds(entities: PermissionEntity[], actions: PermissionAction[], login: LoginHolder | null, tenantId: string | null | undefined): boolean {
    if (entities.length === 0) return false
    if (!login) return false
    if (!tenantId) return false
    if (tenantId !== SUPER_TENANT_ID && login.permissionsByTenant[SUPER_TENANT_ID]?.length > 0) {
        // check if a super admin is present
        const isSuperPermitted = permitsWithoutIds(entities, actions, login, SUPER_TENANT_ID)
        if (isSuperPermitted) return true
    }
    const loginPermissions = login.permissionsByTenant[tenantId]
    if (!loginPermissions) return false
    const isEntityPermitted = loginPermissions.some(p => p.entities.includes(PermissionEntity.ANY)) || entities.some(e => loginPermissions.some(permission => permission.entities.includes(e)))
    if (!isEntityPermitted) return false
    const isActionPermitted = loginPermissions.some(p => p.actions.includes(PermissionAction.ALL)) || actions.some(a => loginPermissions.some(p => p.actions.includes(a)))
    return isEntityPermitted && isActionPermitted
}

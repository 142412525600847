import {Page, Pageable} from "../adapters/interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {CreateTenantSettingRequest} from "../generated/models/CreateTenantSettingRequest";
import {TenantSettingResponse} from "../generated/models/TenantSettingResponse";
import {UpdateTenantSettingRequest} from "../generated/models/UpdateTenantSettingRequest";
import {TenantSettingDefinitionResponse} from "../generated/models/TenantSettingDefinitionResponse";
import {TenantSettingAdapter, TenantSettingId} from "../adapters/TenantSettingAdapter";

export class TenantSettingAdapterDemo extends TenantSettingAdapter {

    create(login: LoginHolder | null, elements: any[]): Promise<TenantSettingResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateTenantSettingRequest): Promise<TenantSettingResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<TenantSettingResponse>> {
        return Promise.reject("Not implemented")
    }

    findDefinitions(login: LoginHolder | null, request: Pageable): Promise<Page<TenantSettingDefinitionResponse>> {
        return Promise.reject("Not implemented")
    }

    get(login: LoginHolder | null, id: TenantSettingId): Promise<TenantSettingResponse | null> {
        return Promise.reject("Not implemented")
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateTenantSettingRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

    delete(login: LoginHolder | null, ids: TenantSettingId[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    deleteOne(login: LoginHolder | null, id: TenantSettingId): Promise<void> {
        return Promise.reject("Not implemented")
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiKeyRoleResponse,
    ApiKeyRoleResponseFromJSON,
    ApiKeyRoleResponseFromJSONTyped,
    ApiKeyRoleResponseToJSON,
} from './ApiKeyRoleResponse';

/**
 * 
 * @export
 * @interface ApiKeyResponse
 */
export interface ApiKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyResponse
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyResponse
     */
    description?: string;
    /**
     * 
     * @type {Array<ApiKeyRoleResponse>}
     * @memberof ApiKeyResponse
     */
    roles: Array<ApiKeyRoleResponse>;
    /**
     * 
     * @type {Date}
     * @memberof ApiKeyResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiKeyResponse
     */
    updatedAt: Date;
}

export function ApiKeyResponseFromJSON(json: any): ApiKeyResponse {
    return ApiKeyResponseFromJSONTyped(json, false);
}

export function ApiKeyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'password': json['password'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'roles': ((json['roles'] as Array<any>).map(ApiKeyRoleResponseFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ApiKeyResponseToJSON(value?: ApiKeyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'password': value.password,
        'description': value.description,
        'roles': ((value.roles as Array<any>).map(ApiKeyRoleResponseToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


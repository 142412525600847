/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentEndpoints,
    DeploymentEndpointsFromJSON,
    DeploymentEndpointsFromJSONTyped,
    DeploymentEndpointsToJSON,
} from './DeploymentEndpoints';
import {
    DeploymentEnvironment,
    DeploymentEnvironmentFromJSON,
    DeploymentEnvironmentFromJSONTyped,
    DeploymentEnvironmentToJSON,
} from './DeploymentEnvironment';
import {
    DeploymentImage,
    DeploymentImageFromJSON,
    DeploymentImageFromJSONTyped,
    DeploymentImageToJSON,
} from './DeploymentImage';
import {
    DeploymentReachability,
    DeploymentReachabilityFromJSON,
    DeploymentReachabilityFromJSONTyped,
    DeploymentReachabilityToJSON,
} from './DeploymentReachability';
import {
    DeploymentResources,
    DeploymentResourcesFromJSON,
    DeploymentResourcesFromJSONTyped,
    DeploymentResourcesToJSON,
} from './DeploymentResources';
import {
    DeploymentTelemetry,
    DeploymentTelemetryFromJSON,
    DeploymentTelemetryFromJSONTyped,
    DeploymentTelemetryToJSON,
} from './DeploymentTelemetry';

/**
 * 
 * @export
 * @interface DeploymentSpecification
 */
export interface DeploymentSpecification {
    /**
     * 
     * @type {DeploymentImage}
     * @memberof DeploymentSpecification
     */
    image: DeploymentImage;
    /**
     * 
     * @type {DeploymentResources}
     * @memberof DeploymentSpecification
     */
    resources: DeploymentResources;
    /**
     * 
     * @type {DeploymentEnvironment}
     * @memberof DeploymentSpecification
     */
    environment: DeploymentEnvironment;
    /**
     * 
     * @type {DeploymentEndpoints}
     * @memberof DeploymentSpecification
     */
    endpoints: DeploymentEndpoints;
    /**
     * 
     * @type {DeploymentTelemetry}
     * @memberof DeploymentSpecification
     */
    telemetry: DeploymentTelemetry;
    /**
     * 
     * @type {DeploymentReachability}
     * @memberof DeploymentSpecification
     */
    reachability: DeploymentReachability;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeploymentSpecification
     */
    tags: Array<string>;
}

export function DeploymentSpecificationFromJSON(json: any): DeploymentSpecification {
    return DeploymentSpecificationFromJSONTyped(json, false);
}

export function DeploymentSpecificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentSpecification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': DeploymentImageFromJSON(json['image']),
        'resources': DeploymentResourcesFromJSON(json['resources']),
        'environment': DeploymentEnvironmentFromJSON(json['environment']),
        'endpoints': DeploymentEndpointsFromJSON(json['endpoints']),
        'telemetry': DeploymentTelemetryFromJSON(json['telemetry']),
        'reachability': DeploymentReachabilityFromJSON(json['reachability']),
        'tags': json['tags'],
    };
}

export function DeploymentSpecificationToJSON(value?: DeploymentSpecification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': DeploymentImageToJSON(value.image),
        'resources': DeploymentResourcesToJSON(value.resources),
        'environment': DeploymentEnvironmentToJSON(value.environment),
        'endpoints': DeploymentEndpointsToJSON(value.endpoints),
        'telemetry': DeploymentTelemetryToJSON(value.telemetry),
        'reachability': DeploymentReachabilityToJSON(value.reachability),
        'tags': value.tags,
    };
}


import {Adapter, Page, Pageable} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiDelete, apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {CreateUserSettingRequest} from "../generated/models/CreateUserSettingRequest";
import {UserSettingResponse, UserSettingResponseFromJSON} from "../generated/models/UserSettingResponse";
import {UpdateUserSettingRequest} from "../generated/models/UpdateUserSettingRequest";
import {
    UserSettingDefinitionResponse,
    UserSettingDefinitionResponseFromJSON
} from "../generated/models/UserSettingDefinitionResponse";

export class UserSettingAdapter implements Adapter<string, UserSettingResponse> {
    private readonly base: string = `/user-settings`

    create(login: LoginHolder | null, elements: any[]): Promise<UserSettingResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateUserSettingRequest): Promise<UserSettingResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return UserSettingResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<UserSettingResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<UserSettingResponse>
                })
    }

    findDefinitions(login: LoginHolder | null, request: Pageable): Promise<Page<UserSettingDefinitionResponse>> {
        return apiGet(login, `${this.base}/definitions?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    const mappedPage = PageFromJSON(response)
                    mappedPage.elements = response.elements.map((e: any) => UserSettingDefinitionResponseFromJSON(e))
                    return mappedPage as Page<UserSettingDefinitionResponse>
                })
    }

    get(login: LoginHolder | null, id: string): Promise<UserSettingResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return UserSettingResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateUserSettingRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.name}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    delete(login: LoginHolder | null, ids: string[]): Promise<void> {
        return apiDelete(login, `${this.base}/batch`, {names: ids})
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    deleteOne(login: LoginHolder | null, id: string): Promise<void> {
        return apiDelete(login, `${this.base}/${id}`)
                .then(() => {
                    console.debug("Successfully deleted element", id)
                })
    }

}

export enum UserSettingDefinition {
    DEFAULT_TENANT
}

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeploymentVariable
 */
export interface DeploymentVariable {
    /**
     * 
     * @type {string}
     * @memberof DeploymentVariable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentVariable
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentVariable
     */
    encrypted: boolean;
}

export function DeploymentVariableFromJSON(json: any): DeploymentVariable {
    return DeploymentVariableFromJSONTyped(json, false);
}

export function DeploymentVariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentVariable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
        'encrypted': json['encrypted'],
    };
}

export function DeploymentVariableToJSON(value?: DeploymentVariable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'encrypted': value.encrypted,
    };
}


import {api} from "../misc/api";

export class UiConfigAdapter {
    private readonly path: string = process.env.GATSBY_CONFIG_URL || ""

    config(): Promise<any> {
        return api(null, "GET", this.path, null, null, {}, false, "")
    }

}

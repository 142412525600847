/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentMetricsResponse,
    DeploymentMetricsResponseFromJSON,
    DeploymentMetricsResponseFromJSONTyped,
    DeploymentMetricsResponseToJSON,
} from './DeploymentMetricsResponse';

/**
 * 
 * @export
 * @interface DeploymentMetricsRangeResponseMetrics
 */
export interface DeploymentMetricsRangeResponseMetrics {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof DeploymentMetricsRangeResponseMetrics
     */
    timestamp: number;
    /**
     * 
     * @type {DeploymentMetricsResponse}
     * @memberof DeploymentMetricsRangeResponseMetrics
     */
    element: DeploymentMetricsResponse;
}

export function DeploymentMetricsRangeResponseMetricsFromJSON(json: any): DeploymentMetricsRangeResponseMetrics {
    return DeploymentMetricsRangeResponseMetricsFromJSONTyped(json, false);
}

export function DeploymentMetricsRangeResponseMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentMetricsRangeResponseMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'element': DeploymentMetricsResponseFromJSON(json['element']),
    };
}

export function DeploymentMetricsRangeResponseMetricsToJSON(value?: DeploymentMetricsRangeResponseMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'element': DeploymentMetricsResponseToJSON(value.element),
    };
}


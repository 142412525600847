/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CpuResourceMetricsResponse,
    CpuResourceMetricsResponseFromJSON,
    CpuResourceMetricsResponseFromJSONTyped,
    CpuResourceMetricsResponseToJSON,
} from './CpuResourceMetricsResponse';
import {
    MemoryResourceMetricsResponse,
    MemoryResourceMetricsResponseFromJSON,
    MemoryResourceMetricsResponseFromJSONTyped,
    MemoryResourceMetricsResponseToJSON,
} from './MemoryResourceMetricsResponse';

/**
 * 
 * @export
 * @interface ResourceMetricsResponse
 */
export interface ResourceMetricsResponse {
    /**
     * 
     * @type {CpuResourceMetricsResponse}
     * @memberof ResourceMetricsResponse
     */
    cpu: CpuResourceMetricsResponse;
    /**
     * 
     * @type {MemoryResourceMetricsResponse}
     * @memberof ResourceMetricsResponse
     */
    memory: MemoryResourceMetricsResponse;
}

export function ResourceMetricsResponseFromJSON(json: any): ResourceMetricsResponse {
    return ResourceMetricsResponseFromJSONTyped(json, false);
}

export function ResourceMetricsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceMetricsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cpu': CpuResourceMetricsResponseFromJSON(json['cpu']),
        'memory': MemoryResourceMetricsResponseFromJSON(json['memory']),
    };
}

export function ResourceMetricsResponseToJSON(value?: ResourceMetricsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cpu': CpuResourceMetricsResponseToJSON(value.cpu),
        'memory': MemoryResourceMetricsResponseToJSON(value.memory),
    };
}


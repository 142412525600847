import {isBrowser} from "./misc";
import {PermissionHolder, WildcardPermission} from "./authorization";
import jwt_decode from "jwt-decode";
import {LoginHolder} from "../components/provider/LoginProvider";
import {PageableRequest, userAdapter} from "../adapters/interfaces";

export const JWT_EMAIL_CLAIM: string = "email" // see https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
export const JWT_FIRST_NAME_CLAIM: string = "given_name" // see https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
export const JWT_LAST_NAME_CLAIM: string = "family_name" // see https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims

export const setupLogin: (token: string,
                          isInternal: boolean,
                          setLogin: (login: LoginHolder | null) => void,
) => Promise<void> = (token, isInternal, setLogin) => {
    const decodedToken = jwt_decode(token) as any
    const userId = decodedToken.sub
    const loginHolder = {
        id: userId,
        email: decodedToken[JWT_EMAIL_CLAIM],
        firstname: decodedToken[JWT_FIRST_NAME_CLAIM],
        lastname: decodedToken[JWT_LAST_NAME_CLAIM],
        expiresAt: decodedToken.exp,
        token: token,
        permissionsByTenant: {},
        isInternal,
    }
    setLogin(loginHolder)

    return new Promise<PermissionHolder>(((resolve, reject) => {
        console.debug("Setting permissions")
        userAdapter.findRoles(loginHolder, new PageableRequest(0, 1000, `userId==${userId}`)).then(response => {
            resolve(
                    response.elements.reduce(function (previous, current) {
                        previous[current.tenantId] = previous[current.tenantId] || []
                        current.permissions.map(p => new WildcardPermission(p)).forEach(p => {
                            previous[current.tenantId].push(p)
                        })
                        return previous
                    }, Object.create({}))
            )
        }).catch(error => reject(error))
    })).then((permissionsByTenant: PermissionHolder) => {
        setLogin({...loginHolder, permissionsByTenant})
        return Promise.resolve()
    })
}

export interface LoginResponse {
    token: string
}

export interface ErrorResponse {
    message: string | null
    type: string | null
}

export const isLoggedIn = (login: LoginHolder | null) => {
    if (!isBrowser) return false
    if (login == null) return false
    const nowInSec = Date.now() / 1000
    return login.expiresAt >= nowInSec
}

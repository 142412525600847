/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentEndpoint,
    DeploymentEndpointFromJSON,
    DeploymentEndpointFromJSONTyped,
    DeploymentEndpointToJSON,
} from './DeploymentEndpoint';

/**
 * 
 * @export
 * @interface DeploymentEndpoints
 */
export interface DeploymentEndpoints {
    /**
     * 
     * @type {DeploymentEndpoint}
     * @memberof DeploymentEndpoints
     */
    health: DeploymentEndpoint;
    /**
     * 
     * @type {DeploymentEndpoint}
     * @memberof DeploymentEndpoints
     */
    ready: DeploymentEndpoint;
    /**
     * 
     * @type {DeploymentEndpoint}
     * @memberof DeploymentEndpoints
     */
    started: DeploymentEndpoint;
    /**
     * 
     * @type {DeploymentEndpoint}
     * @memberof DeploymentEndpoints
     */
    openApi: DeploymentEndpoint;
}

export function DeploymentEndpointsFromJSON(json: any): DeploymentEndpoints {
    return DeploymentEndpointsFromJSONTyped(json, false);
}

export function DeploymentEndpointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentEndpoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'health': DeploymentEndpointFromJSON(json['health']),
        'ready': DeploymentEndpointFromJSON(json['ready']),
        'started': DeploymentEndpointFromJSON(json['started']),
        'openApi': DeploymentEndpointFromJSON(json['openApi']),
    };
}

export function DeploymentEndpointsToJSON(value?: DeploymentEndpoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'health': DeploymentEndpointToJSON(value.health),
        'ready': DeploymentEndpointToJSON(value.ready),
        'started': DeploymentEndpointToJSON(value.started),
        'openApi': DeploymentEndpointToJSON(value.openApi),
    };
}


import {LoginHolder} from "../components/provider/LoginProvider";
import {MetricsAdapter} from "../adapters/MetricsAdapter";
import {ResourceMetricsResponse} from "../generated/models/ResourceMetricsResponse";
import {ResourceMetricsRangeResponse} from "../generated/models/ResourceMetricsRangeResponse";
import {TenantMetricsRangeResponse} from "../generated/models/TenantMetricsRangeResponse";
import {UserMetricsRangeResponse} from "../generated/models/UserMetricsRangeResponse";
import {ApiKeyMetricsRangeResponse} from "../generated/models/ApiKeyMetricsRangeResponse";
import {DeploymentMetricsRangeResponse} from "../generated/models/DeploymentMetricsRangeResponse";

export class MetricsAdapterDemo extends MetricsAdapter {

    resourcesAt(login: LoginHolder | null, timestamp: Date, tenantId: string | null = null): Promise<ResourceMetricsResponse> {
        return Promise.reject("Not implemented")
    }

    resources(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number, tenantId: string | null = null): Promise<ResourceMetricsRangeResponse> {
        return Promise.reject("Not implemented")
    }

    tenants(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number): Promise<TenantMetricsRangeResponse> {
        return Promise.reject("Not implemented")
    }

    users(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number): Promise<UserMetricsRangeResponse> {
        return Promise.reject("Not implemented")
    }

    apiKeys(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number): Promise<ApiKeyMetricsRangeResponse> {
        return Promise.reject("Not implemented")
    }

    deployments(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number, tenantId: string | null = null): Promise<DeploymentMetricsRangeResponse> {
        return Promise.reject("Not implemented")
    }

}

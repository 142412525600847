import {Adapter, Page, Pageable} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiDelete} from "../misc/api";

export abstract class AbstractAdapter<ID, ELEMENT> implements Adapter<ID, ELEMENT> {
    protected abstract basePath(): string

    abstract create(login: LoginHolder | null, elements: any[]): Promise<ELEMENT[]>;

    abstract createOne(login: LoginHolder | null, element: any): Promise<ELEMENT>;

    delete(login: LoginHolder | null, ids: ID[]): Promise<void> {
        return apiDelete(login, `${this.basePath()}/batch`, {ids: ids})
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    deleteOne(login: LoginHolder | null, id: ID): Promise<void> {
        return apiDelete(login, `${this.basePath()}/${id}`)
                .then(() => {
                    console.debug("Successfully deleted element", id)
                })
    }

    abstract find(login: LoginHolder | null, request: Pageable): Promise<Page<ELEMENT>>;

    abstract get(login: LoginHolder | null, id: ID): Promise<ELEMENT | null>;

    abstract update(login: LoginHolder | null, elements: any[]): Promise<void>;

    abstract updateOne(login: LoginHolder | null, element: any): Promise<void>;

}
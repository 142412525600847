import {apiGet, apiPost} from "../misc/api";
import {LoginUserRequest} from "../generated/models/LoginUserRequest";
import {LoginUserResponse, LoginUserResponseFromJSON} from "../generated/models/LoginUserResponse";
import {LoginHolder} from "../components/provider/LoginProvider";

export class AuthAdapter {
    private readonly base: string = `/login`

    login(element: LoginUserRequest): Promise<LoginUserResponse> {
        return apiPost(null, this.base, element, {}, false)
                .then(response => {
                    console.debug("Successfully logged in", response)
                    return LoginUserResponseFromJSON(response)
                })
    }

    logout(login: LoginHolder | null): Promise<any> {
        return apiGet(login, `/logout`)
    }

}

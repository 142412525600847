import {Page, Pageable, UUID} from "../adapters/interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {NotificationResponse} from "../generated/models/NotificationResponse";
import {CreateNotificationRequest} from "../generated/models/CreateNotificationRequest";
import {Count} from "../generated/models/Count";
import {NotificationAdapter} from "../adapters/NotificationAdapter";

export class NotificationAdapterDemo extends NotificationAdapter {

    create(login: LoginHolder | null, elements: any[]): Promise<NotificationResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateNotificationRequest): Promise<NotificationResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<NotificationResponse>> {
        return Promise.reject("Not implemented")
    }

    get(login: LoginHolder | null, id: UUID): Promise<NotificationResponse | null> {
        return Promise.reject("Not implemented")
    }

    updateSeen(login: LoginHolder | null, ids: UUID[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOneSeen(login: LoginHolder | null, id: UUID): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOneUnseen(login: LoginHolder | null, id: UUID): Promise<void> {
        return Promise.reject("Not implemented")
    }

    countUnseen(login: LoginHolder | null): Promise<Count> {
        return Promise.resolve({count: 0})
    }

}

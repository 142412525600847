/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentMetricsRangeResponseMetrics,
    DeploymentMetricsRangeResponseMetricsFromJSON,
    DeploymentMetricsRangeResponseMetricsFromJSONTyped,
    DeploymentMetricsRangeResponseMetricsToJSON,
} from './DeploymentMetricsRangeResponseMetrics';

/**
 * 
 * @export
 * @interface DeploymentMetricsRangeResponse
 */
export interface DeploymentMetricsRangeResponse {
    /**
     * 
     * @type {Array<DeploymentMetricsRangeResponseMetrics>}
     * @memberof DeploymentMetricsRangeResponse
     */
    metrics: Array<DeploymentMetricsRangeResponseMetrics>;
}

export function DeploymentMetricsRangeResponseFromJSON(json: any): DeploymentMetricsRangeResponse {
    return DeploymentMetricsRangeResponseFromJSONTyped(json, false);
}

export function DeploymentMetricsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentMetricsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': ((json['metrics'] as Array<any>).map(DeploymentMetricsRangeResponseMetricsFromJSON)),
    };
}

export function DeploymentMetricsRangeResponseToJSON(value?: DeploymentMetricsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': ((value.metrics as Array<any>).map(DeploymentMetricsRangeResponseMetricsToJSON)),
    };
}


import {GlobalSettingAdapter, GlobalSettingDefinition} from "../adapters/GlobalSettingAdapter";
import {LoginHolder} from "../components/provider/LoginProvider";
import {SettingResponse} from "../generated/models/SettingResponse";
import {CreateSettingRequest} from "../generated/models/CreateSettingRequest";
import {Page, Pageable} from "../adapters/interfaces";
import {SettingDefinitionResponse} from "../generated/models/SettingDefinitionResponse";
import {UpdateSettingRequest} from "../generated/models/UpdateSettingRequest";

export class GlobalSettingAdapterDemo extends GlobalSettingAdapter {

    create(login: LoginHolder | null, elements: any[]): Promise<SettingResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateSettingRequest): Promise<SettingResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<SettingResponse>> {
        return Promise.reject("Not implemented")
    }

    findDefinitions(login: LoginHolder | null, request: Pageable): Promise<Page<SettingDefinitionResponse>> {
        return Promise.reject("Not implemented")
    }

    get(login: LoginHolder | null, id: string): Promise<SettingResponse | null> {
        return Promise.reject("Not implemented")
    }

    getValue(login: LoginHolder | null, id: string): Promise<string> {
        switch (id) {
            case GlobalSettingDefinition[GlobalSettingDefinition.PASSWORD_POLICY]:
                return Promise.resolve("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$")
            case GlobalSettingDefinition[GlobalSettingDefinition.PASSWORD_HELP]:
                return Promise.resolve("Requirements: min. 8 characters, 1 upper case letter, 1 lower case letter and 1 number")
            default:
                return Promise.reject("Not implemented")
        }
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateSettingRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

    delete(login: LoginHolder | null, ids: string[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    deleteOne(login: LoginHolder | null, id: string): Promise<void> {
        return Promise.reject("Not implemented")
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SettingDefinitionResponse
 */
export interface SettingDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof SettingDefinitionResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDefinitionResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDefinitionResponse
     */
    help: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDefinitionResponse
     */
    _default?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingDefinitionResponse
     */
    regex: string;
}

export function SettingDefinitionResponseFromJSON(json: any): SettingDefinitionResponse {
    return SettingDefinitionResponseFromJSONTyped(json, false);
}

export function SettingDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayName': json['displayName'],
        'help': json['help'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'regex': json['regex'],
    };
}

export function SettingDefinitionResponseToJSON(value?: SettingDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'help': value.help,
        'default': value._default,
        'regex': value.regex,
    };
}


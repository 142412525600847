import {CreateAdapter, FindAdapter, GetAdapter, Page, Pageable, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {NotificationResponse, NotificationResponseFromJSON} from "../generated/models/NotificationResponse";
import {CreateNotificationRequest} from "../generated/models/CreateNotificationRequest";
import {Count, CountFromJSON} from "../generated/models/Count";
import {utc} from "moment";
import {groupBy} from "../misc/misc";

export class NotificationAdapter implements CreateAdapter<UUID, NotificationResponse>,
        FindAdapter<UUID, NotificationResponse>, GetAdapter<UUID, NotificationResponse> {
    private readonly base: string = `/notifications`

    create(login: LoginHolder | null, elements: any[]): Promise<NotificationResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateNotificationRequest): Promise<NotificationResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return NotificationResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<NotificationResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<NotificationResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<NotificationResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return NotificationResponseFromJSON(response)
                })
    }

    updateSeen(login: LoginHolder | null, ids: UUID[]): Promise<void> {
        return apiPut(login, `${this.base}/batch/seen`, {ids})
                .then(() => console.debug("Successfully updated elements"))
    }

    updateOneSeen(login: LoginHolder | null, id: UUID): Promise<void> {
        return apiPut(login, `${this.base}/${id}/seen`)
                .then(() => console.debug("Successfully updated element"))
    }

    updateOneUnseen(login: LoginHolder | null, id: UUID): Promise<void> {
        return apiPut(login, `${this.base}/${id}/unseen`)
                .then(() => console.debug("Successfully updated element"))
    }

    countUnseen(login: LoginHolder | null): Promise<Count> {
        return apiGet(login, `${this.base}/unseen`)
                .then(response => {
                    console.debug("Successfully retrieved count", response)
                    return CountFromJSON(response)
                })
    }

}

export interface NotificationGroup {
    today: NotificationResponse[]
    yesterday: NotificationResponse[]
    thisWeek: NotificationResponse[]
    lastWeek: NotificationResponse[]
    older: NotificationResponse[]
    total: number
}

export enum NotificationGroupKey {
    TODAY,
    YESTERDAY,
    THIS_WEEK,
    LAST_WEEK,
    OLDER,
}

export const emptyNotificationGroup: NotificationGroup = {
    today: [],
    yesterday: [],
    thisWeek: [],
    lastWeek: [],
    older: [],
    total: 0,
}

export function groupNotifications(notifications: NotificationResponse[], now: Date = new Date()): NotificationGroup {
    if (!notifications) return emptyNotificationGroup

    const todayStart = utc(now).startOf('day')
    const todayEnd = utc(now).endOf('day')

    const yesterdayStart = utc(now).subtract(1, 'day').startOf('day')
    const yesterdayEnd = utc(now).subtract(1, 'day').endOf('day')

    const thisWeekStart = utc(now).startOf('isoWeek')
    const thisWeekEnd = utc(now).endOf('isoWeek')

    const lastWeekStart = utc(now).subtract(7, 'days').startOf('isoWeek')
    const lastWeekEnd = utc(now).subtract(7, 'days').endOf('isoWeek')

    const groups = groupBy(notifications, notification => {
        const timestamp = utc(notification.createdAt)
        if (timestamp.isBetween(todayStart, todayEnd)) return NotificationGroupKey.TODAY
        else if (timestamp.isBetween(yesterdayStart, yesterdayEnd)) return NotificationGroupKey.YESTERDAY
        else if (timestamp.isBetween(thisWeekStart, thisWeekEnd)) return NotificationGroupKey.THIS_WEEK
        else if (timestamp.isBetween(lastWeekStart, lastWeekEnd)) return NotificationGroupKey.LAST_WEEK
        else return NotificationGroupKey.OLDER
    })
    const sortFunction = (a: NotificationResponse, b: NotificationResponse) => a.createdAt > b.createdAt ? -1 : 1
    return {
        today: groups[NotificationGroupKey.TODAY] ? groups[NotificationGroupKey.TODAY].sort(sortFunction) : [],
        yesterday: groups[NotificationGroupKey.YESTERDAY] ? groups[NotificationGroupKey.YESTERDAY].sort(sortFunction) : [],
        thisWeek: groups[NotificationGroupKey.THIS_WEEK] ? groups[NotificationGroupKey.THIS_WEEK].sort(sortFunction) : [],
        lastWeek: groups[NotificationGroupKey.LAST_WEEK] ? groups[NotificationGroupKey.LAST_WEEK].sort(sortFunction) : [],
        older: groups[NotificationGroupKey.OLDER] ? groups[NotificationGroupKey.OLDER].sort(sortFunction) : [],
        total: notifications.length,
    }
}

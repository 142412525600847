/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeploymentImage
 */
export interface DeploymentImage {
    /**
     * 
     * @type {string}
     * @memberof DeploymentImage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentImage
     */
    tag: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeploymentImage
     */
    ports?: Array<number>;
}

export function DeploymentImageFromJSON(json: any): DeploymentImage {
    return DeploymentImageFromJSONTyped(json, false);
}

export function DeploymentImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'tag': json['tag'],
        'ports': !exists(json, 'ports') ? undefined : json['ports'],
    };
}

export function DeploymentImageToJSON(value?: DeploymentImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'tag': value.tag,
        'ports': value.ports,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantUserResponse
 */
export interface TenantUserResponse {
    /**
     * 
     * @type {string}
     * @memberof TenantUserResponse
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof TenantUserResponse
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantUserResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantUserResponse
     */
    updatedAt: Date;
}

export function TenantUserResponseFromJSON(json: any): TenantUserResponse {
    return TenantUserResponseFromJSONTyped(json, false);
}

export function TenantUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'],
        'userId': json['userId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function TenantUserResponseToJSON(value?: TenantUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'userId': value.userId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


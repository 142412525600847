import {createTheme} from '@mui/material/styles';
import {CancelSharp, CheckCircleSharp, InfoSharp, WarningSharp} from "@mui/icons-material";
import React from "react";

// noinspection JSUnusedLocalSymbols
const metropolisSemiBold = {
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    fontWeight: '700',
}

const montserratRegular = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
}

// noinspection JSUnusedLocalSymbols
const montserratMedium = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
}

// noinspection JSUnusedLocalSymbols
const montserratBold = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '700',
}

// noinspection JSUnusedLocalSymbols
const firaCodeRegular = {
    fontFamily: 'Fira Code',
    fontStyle: 'normal',
    fontWeight: '400',
}

// noinspection JSUnusedLocalSymbols
const firaCodeBold = {
    fontFamily: 'Fira Code',
    fontStyle: 'normal',
    fontWeight: '700',
}

export const stratos = {
    highLight: '#31333B',
    light: '#23252c',
    main: '#1c1e24',
    dark: '#010622',
    contrastText: '#010622',
}
export const mint = {
    light: '#33ffbe',
    main: '#00ffae',
    dark: '#00b279',
    contrastText: '#000',
}
export const violet = {
    light: '#9b51ff',
    main: '#5d19ff',
    dark: '#0000ca',
    contrastText: '#fff',
}
export const pink = {
    light: '#ff62ff',
    main: '#da19ff',
    dark: '#a300cb',
    contrastText: '#000',
}
export const blue = {
    light: '#6dfaff',
    main: '#0dc7ff',
    dark: '#0096cc',
    contrastText: '#000',
}
export const ok = {
    light: '#72ff65',
    main: '#0dff29',
    dark: '#00ca00',
    contrastText: '#000',
}
export const alert = {
    light: '#ffff56',
    main: '#ffec00',
    dark: '#c7ba00',
    contrastText: '#000',
}
export const error = {
    light: '#ff5b76',
    main: '#ff004b',
    dark: '#c40024',
    contrastText: '#000',
}

const baseTheme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
    components: {
        MuiCssBaseline: {
            //@ts-ignore
            '@global': {'@font-face': [montserratRegular]},
        },
        MuiAppBar: {defaultProps: {color: 'default', elevation: 0}},
        MuiPaper: {styleOverrides: {root: {borderRadius: 0}, rounded: {borderRadius: 0}}},
        MuiCard: {defaultProps: {elevation: 0}, styleOverrides: {root: {borderRadius: 0}}},
        MuiDataGrid: {defaultProps: {style: {borderRadius: 0}}, styleOverrides: {root: {borderRadius: 0}}}, // defaultProps setting for disabling borderRadius is necessary because styleOverrides is not working
        MuiAlert: {
            defaultProps: {
                variant: 'outlined',
                iconMapping: {
                    success: <CheckCircleSharp fontStyle="inherit"/>,
                    warning: <WarningSharp fontStyle="inherit"/>,
                    error: <CancelSharp fontStyle="inherit"/>,
                    info: <InfoSharp fontStyle="inherit"/>,
                },
            }, styleOverrides: {root: {borderRadius: 0, width: '100%'}}
        },
        MuiTooltip: {styleOverrides: {tooltip: {borderRadius: 0}}},
        MuiChip: {styleOverrides: {root: {borderRadius: 0}}},
        MuiButton: {defaultProps: {disableElevation: true}, styleOverrides: {root: {borderRadius: 0}}},
        MuiInput: {styleOverrides: {root: {borderRadius: 0}}},
        MuiOutlinedInput: {styleOverrides: {root: {borderRadius: 0}}},
        MuiCheckbox: {styleOverrides: {root: {borderRadius: 0}}},
        MuiCircularProgress: {
            defaultProps: {disableShrink: true},
            styleOverrides: {root: {animationDuration: '500ms'}}
        },
        MuiBackdrop: {defaultProps: {transitionDuration: 0}},
    },
})
const lightTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: 'light',
        primary: violet,
        secondary: blue,
        info: violet,
        success: ok,
        warning: alert,
        error: error,
    },
    components: {
        ...baseTheme.components,
        MuiCssBaseline: {
            styleOverrides: {body: {backgroundColor: baseTheme.palette.background.default}},
            //@ts-ignore
            '@global': {'@font-face': [montserratRegular]},
        },
    },
})
const darkTheme = createTheme({
    ...baseTheme,
    palette: {
        mode: 'dark',
        primary: blue,
        secondary: pink,
        info: blue,
        success: ok,
        warning: alert,
        error: error,
        background: {
            default: stratos.dark,
            paper: stratos.light,
        }
    },
    components: {
        ...baseTheme.components,
        MuiCssBaseline: {
            styleOverrides: {body: {backgroundColor: stratos.main}},
            //@ts-ignore
            '@global': {'@font-face': [montserratRegular]},
        },
    },
})

export {lightTheme, darkTheme};

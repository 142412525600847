import {LoginHolder} from "../components/provider/LoginProvider";
import {UserAdapter} from "./UserAdapter";
import {TenantAdapter} from "./TenantAdapter";
import {UserSettingAdapter} from "./UserSettingAdapter";
import {TenantSettingAdapter} from "./TenantSettingAdapter";
import {ApiKeyAdapter} from "./ApiKeyAdapter";
import {GlobalSettingAdapter} from "./GlobalSettingAdapter";
import {NotificationAdapter} from "./NotificationAdapter";
import {DeploymentAdapter} from "./DeploymentAdapter";
import {UiConfigAdapter} from "./UiConfigAdapter";
import {AuthAdapter} from "./AuthAdapter";
import {MetricsAdapter} from "./MetricsAdapter";
import {UiConfigAdapterDemo} from "../demo/UiConfigAdapterDemo";
import {AuthAdapterDemo} from "../demo/AuthAdapterDemo";
import {GlobalSettingAdapterDemo} from "../demo/GlobalSettingAdapterDemo";
import {UserAdapterDemo} from "../demo/UserAdapterDemo";
import {UserSettingAdapterDemo} from "../demo/UserSettingAdapterDemo";
import {TenantAdapterDemo} from "../demo/TenantAdapterDemo";
import {TenantSettingAdapterDemo} from "../demo/TenantSettingAdapterDemo";
import {ApiKeyAdapterDemo} from "../demo/ApiKeyAdapterDemo";
import {NotificationAdapterDemo} from "../demo/NotificationAdapterDemo";
import {DeploymentAdapterDemo} from "../demo/DeploymentAdapterDemo";
import {MetricsAdapterDemo} from "../demo/MetricAdapterDemo";

export type UUID = string

export const pageable: (number: number | null, size: number | null, filter: string | null, sort: string | null) => Pageable = (number: number | null = null, size: number | null = null, filter: string | null = null, sort: string | null = null) => {
    return new PageableRequest(number, size, filter, sort)
}
export const uiConfigAdapter: UiConfigAdapter = new UiConfigAdapterDemo()
export const authAdapter: AuthAdapter = new AuthAdapterDemo()
export const globalSettingAdapter: GlobalSettingAdapter = new GlobalSettingAdapterDemo()
export const userAdapter: UserAdapter = new UserAdapterDemo()
export const userSettingAdapter: UserSettingAdapter = new UserSettingAdapterDemo()
export const tenantAdapter: TenantAdapter = new TenantAdapterDemo()
export const tenantSettingAdapter: TenantSettingAdapter = new TenantSettingAdapterDemo()
export const apiKeyAdapter: ApiKeyAdapter = new ApiKeyAdapterDemo()
export const notificationAdapter: NotificationAdapter = new NotificationAdapterDemo()
export const deploymentAdapter: DeploymentAdapter = new DeploymentAdapterDemo()
export const metricsAdapter: MetricsAdapter = new MetricsAdapterDemo()


export interface Page<ELEMENT> {
    number: number
    size: number
    totalPages: number
    totalElements: number
    elements: ELEMENT[]
}

export class PageResponse<ELEMENT> implements Page<ELEMENT> {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
    elements: ELEMENT[];

    constructor(number: number = 0, size: number = 0, totalElements: number = 0, elements: ELEMENT[] = []) {
        this.number = number
        this.size = size
        this.totalElements = totalElements
        this.totalPages = Math.ceil(totalElements / size)
        this.elements = elements
    }
}

export interface Pageable {
    number: number | null
    size: number | null
    filter: string | null
    sort: string | null

    toQueryString(): string
}

export class PageableRequest implements Pageable {
    number: number | null;
    size: number | null;
    filter: string | null;
    sort: string | null;

    constructor(number: number | null = null, size: number | null = null, filter: string | null = null, sort: string | null = null) {
        this.number = number
        this.size = size
        this.filter = filter
        this.sort = sort
    }

    toQueryString(): string {
        const page = this.number != null ? `page=${this.number}` : ""
        const size = this.size != null ? `size=${this.size}` : ""
        const filter = this.filter != null ? `filter=${encodeURIComponent(this.filter)}` : ""
        const sort = this.sort != null ? `sort=${encodeURIComponent(this.sort)}` : ""
        return [page, size, filter, sort].filter(s => s.length != 0).join("&")
    }
}


export interface Adapter<ID, ELEMENT> extends CreateAdapter<ID, ELEMENT>,
        FindAdapter<ID, ELEMENT>, GetAdapter<ID, ELEMENT>, DeleteAdapter<ID, ELEMENT>, UpdateAdapter<ID, ELEMENT> {
}

export interface CreateAdapter<ID, ELEMENT> {
    createOne(login: LoginHolder | null, element: any): Promise<ELEMENT>;

    create(login: LoginHolder | null, elements: any[]): Promise<ELEMENT[]>;
}

export interface FindAdapter<ID, ELEMENT> {
    find(login: LoginHolder | null, request: Pageable): Promise<Page<ELEMENT>>;
}

export interface GetAdapter<ID, ELEMENT> {
    get(login: LoginHolder | null, id: ID): Promise<ELEMENT | null>;
}

export interface DeleteAdapter<ID, ELEMENT> {
    deleteOne(login: LoginHolder | null, id: ID): Promise<void>;

    delete(login: LoginHolder | null, ids: ID[]): Promise<void>;
}

export interface UpdateAdapter<ID, ELEMENT> {
    updateOne(login: LoginHolder | null, element: any): Promise<void>;

    update(login: LoginHolder | null, elements: any[]): Promise<void>;
}

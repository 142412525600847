/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantSettingDefinitionResponse
 */
export interface TenantSettingDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof TenantSettingDefinitionResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSettingDefinitionResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSettingDefinitionResponse
     */
    help: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantSettingDefinitionResponse
     */
    encrypted: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantSettingDefinitionResponse
     */
    _default?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantSettingDefinitionResponse
     */
    regex: string;
}

export function TenantSettingDefinitionResponseFromJSON(json: any): TenantSettingDefinitionResponse {
    return TenantSettingDefinitionResponseFromJSONTyped(json, false);
}

export function TenantSettingDefinitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantSettingDefinitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayName': json['displayName'],
        'help': json['help'],
        'encrypted': json['encrypted'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'regex': json['regex'],
    };
}

export function TenantSettingDefinitionResponseToJSON(value?: TenantSettingDefinitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'displayName': value.displayName,
        'help': value.help,
        'encrypted': value.encrypted,
        'default': value._default,
        'regex': value.regex,
    };
}


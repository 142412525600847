import React, {createContext, useState} from "react";
import {getLocalStorageObject, setLocalStorageObject} from "../../misc/misc";


export interface OidcTokenHolder {
    token: string
    refreshToken: string | null
    idToken: string | null
}

export interface OidcTokenContextState {
    oidcToken: OidcTokenHolder | null
    setOidcToken: (oidcToken: OidcTokenHolder | null) => void
}

const initialState: OidcTokenContextState = {
    oidcToken: null, setOidcToken: () => {
    }
}

export const OIDC_TOKENS_STORAGE_KEY = 'aiosOidcTokens'
export const OidcTokenContext = createContext<OidcTokenContextState>(initialState)


const OidcTokenProvider: React.FC = ({children}) => {
    const [oidcToken, setOidcTokens] = useState<OidcTokenHolder | null>(getLocalStorageObject(OIDC_TOKENS_STORAGE_KEY))
    const defineOidcToken = (oidcToken: OidcTokenHolder | null) => {
        setOidcTokens(oidcToken)
        setLocalStorageObject(OIDC_TOKENS_STORAGE_KEY, oidcToken)
    }
    return (
            <OidcTokenContext.Provider value={{oidcToken, setOidcToken: defineOidcToken}}>
                {children}
            </OidcTokenContext.Provider>
    )
}

export default OidcTokenProvider

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiKeyMetricsResponse,
    ApiKeyMetricsResponseFromJSON,
    ApiKeyMetricsResponseFromJSONTyped,
    ApiKeyMetricsResponseToJSON,
} from './ApiKeyMetricsResponse';

/**
 * 
 * @export
 * @interface ApiKeyMetricsRangeResponseMetrics
 */
export interface ApiKeyMetricsRangeResponseMetrics {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof ApiKeyMetricsRangeResponseMetrics
     */
    timestamp: number;
    /**
     * 
     * @type {ApiKeyMetricsResponse}
     * @memberof ApiKeyMetricsRangeResponseMetrics
     */
    element: ApiKeyMetricsResponse;
}

export function ApiKeyMetricsRangeResponseMetricsFromJSON(json: any): ApiKeyMetricsRangeResponseMetrics {
    return ApiKeyMetricsRangeResponseMetricsFromJSONTyped(json, false);
}

export function ApiKeyMetricsRangeResponseMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyMetricsRangeResponseMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'element': ApiKeyMetricsResponseFromJSON(json['element']),
    };
}

export function ApiKeyMetricsRangeResponseMetricsToJSON(value?: ApiKeyMetricsRangeResponseMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'element': ApiKeyMetricsResponseToJSON(value.element),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemoryResourceMetricsResponse
 */
export interface MemoryResourceMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof MemoryResourceMetricsResponse
     */
    requestedInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof MemoryResourceMetricsResponse
     */
    limitedInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof MemoryResourceMetricsResponse
     */
    totalInMegaBytes: number;
}

export function MemoryResourceMetricsResponseFromJSON(json: any): MemoryResourceMetricsResponse {
    return MemoryResourceMetricsResponseFromJSONTyped(json, false);
}

export function MemoryResourceMetricsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemoryResourceMetricsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestedInMegaBytes': json['requestedInMegaBytes'],
        'limitedInMegaBytes': json['limitedInMegaBytes'],
        'totalInMegaBytes': json['totalInMegaBytes'],
    };
}

export function MemoryResourceMetricsResponseToJSON(value?: MemoryResourceMetricsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestedInMegaBytes': value.requestedInMegaBytes,
        'limitedInMegaBytes': value.limitedInMegaBytes,
        'totalInMegaBytes': value.totalInMegaBytes,
    };
}


import {Page, Pageable, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {AbstractAdapter} from "./AbstractAdapter";
import {TenantResponse, TenantResponseFromJSON} from "../generated/models/TenantResponse";
import {UpdateTenantRequest} from "../generated/models/UpdateTenantRequest";
import {CreateTenantRequest} from "../generated/models/CreateTenantRequest";

export class TenantAdapter extends AbstractAdapter<UUID, TenantResponse> {
    private readonly base: string = `/tenants`

    protected basePath(): string {
        return this.base;
    }

    create(login: LoginHolder | null, elements: any[]): Promise<TenantResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateTenantRequest): Promise<TenantResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return TenantResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<TenantResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<TenantResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<TenantResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return TenantResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: UpdateTenantRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateTenantRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiKeyMetricsRangeResponseMetrics,
    ApiKeyMetricsRangeResponseMetricsFromJSON,
    ApiKeyMetricsRangeResponseMetricsFromJSONTyped,
    ApiKeyMetricsRangeResponseMetricsToJSON,
} from './ApiKeyMetricsRangeResponseMetrics';

/**
 * 
 * @export
 * @interface ApiKeyMetricsRangeResponse
 */
export interface ApiKeyMetricsRangeResponse {
    /**
     * 
     * @type {Array<ApiKeyMetricsRangeResponseMetrics>}
     * @memberof ApiKeyMetricsRangeResponse
     */
    metrics: Array<ApiKeyMetricsRangeResponseMetrics>;
}

export function ApiKeyMetricsRangeResponseFromJSON(json: any): ApiKeyMetricsRangeResponse {
    return ApiKeyMetricsRangeResponseFromJSONTyped(json, false);
}

export function ApiKeyMetricsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyMetricsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': ((json['metrics'] as Array<any>).map(ApiKeyMetricsRangeResponseMetricsFromJSON)),
    };
}

export function ApiKeyMetricsRangeResponseToJSON(value?: ApiKeyMetricsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': ((value.metrics as Array<any>).map(ApiKeyMetricsRangeResponseMetricsToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserMetricsResponse,
    UserMetricsResponseFromJSON,
    UserMetricsResponseFromJSONTyped,
    UserMetricsResponseToJSON,
} from './UserMetricsResponse';

/**
 * 
 * @export
 * @interface UserMetricsRangeResponseMetrics
 */
export interface UserMetricsRangeResponseMetrics {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof UserMetricsRangeResponseMetrics
     */
    timestamp: number;
    /**
     * 
     * @type {UserMetricsResponse}
     * @memberof UserMetricsRangeResponseMetrics
     */
    element: UserMetricsResponse;
}

export function UserMetricsRangeResponseMetricsFromJSON(json: any): UserMetricsRangeResponseMetrics {
    return UserMetricsRangeResponseMetricsFromJSONTyped(json, false);
}

export function UserMetricsRangeResponseMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMetricsRangeResponseMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'element': UserMetricsResponseFromJSON(json['element']),
    };
}

export function UserMetricsRangeResponseMetricsToJSON(value?: UserMetricsRangeResponseMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'element': UserMetricsResponseToJSON(value.element),
    };
}


import {Page, Pageable} from "../adapters/interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {CreateUserSettingRequest} from "../generated/models/CreateUserSettingRequest";
import {UserSettingResponse} from "../generated/models/UserSettingResponse";
import {UpdateUserSettingRequest} from "../generated/models/UpdateUserSettingRequest";
import {UserSettingDefinitionResponse} from "../generated/models/UserSettingDefinitionResponse";
import {UserSettingAdapter} from "../adapters/UserSettingAdapter";

export class UserSettingAdapterDemo extends UserSettingAdapter {

    create(login: LoginHolder | null, elements: any[]): Promise<UserSettingResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateUserSettingRequest): Promise<UserSettingResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<UserSettingResponse>> {
        return Promise.reject("Not implemented")
    }

    findDefinitions(login: LoginHolder | null, request: Pageable): Promise<Page<UserSettingDefinitionResponse>> {
        return Promise.reject("Not implemented")
    }

    get(login: LoginHolder | null, id: string): Promise<UserSettingResponse | null> {
        return Promise.reject("Not implemented")
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateUserSettingRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

    delete(login: LoginHolder | null, ids: string[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    deleteOne(login: LoginHolder | null, id: string): Promise<void> {
        return Promise.reject("Not implemented")
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TenantMetricsResponse,
    TenantMetricsResponseFromJSON,
    TenantMetricsResponseFromJSONTyped,
    TenantMetricsResponseToJSON,
} from './TenantMetricsResponse';

/**
 * 
 * @export
 * @interface TenantMetricsRangeResponseMetrics
 */
export interface TenantMetricsRangeResponseMetrics {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof TenantMetricsRangeResponseMetrics
     */
    timestamp: number;
    /**
     * 
     * @type {TenantMetricsResponse}
     * @memberof TenantMetricsRangeResponseMetrics
     */
    element: TenantMetricsResponse;
}

export function TenantMetricsRangeResponseMetricsFromJSON(json: any): TenantMetricsRangeResponseMetrics {
    return TenantMetricsRangeResponseMetricsFromJSONTyped(json, false);
}

export function TenantMetricsRangeResponseMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantMetricsRangeResponseMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'element': TenantMetricsResponseFromJSON(json['element']),
    };
}

export function TenantMetricsRangeResponseMetricsToJSON(value?: TenantMetricsRangeResponseMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'element': TenantMetricsResponseToJSON(value.element),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResourceMetricsResponse,
    ResourceMetricsResponseFromJSON,
    ResourceMetricsResponseFromJSONTyped,
    ResourceMetricsResponseToJSON,
} from './ResourceMetricsResponse';

/**
 * 
 * @export
 * @interface ResourceMetricsRangeResponseMetrics
 */
export interface ResourceMetricsRangeResponseMetrics {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof ResourceMetricsRangeResponseMetrics
     */
    timestamp: number;
    /**
     * 
     * @type {ResourceMetricsResponse}
     * @memberof ResourceMetricsRangeResponseMetrics
     */
    element: ResourceMetricsResponse;
}

export function ResourceMetricsRangeResponseMetricsFromJSON(json: any): ResourceMetricsRangeResponseMetrics {
    return ResourceMetricsRangeResponseMetricsFromJSONTyped(json, false);
}

export function ResourceMetricsRangeResponseMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceMetricsRangeResponseMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'element': ResourceMetricsResponseFromJSON(json['element']),
    };
}

export function ResourceMetricsRangeResponseMetricsToJSON(value?: ResourceMetricsRangeResponseMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'element': ResourceMetricsResponseToJSON(value.element),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    severity: NotificationResponseSeverityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationResponse
     */
    seen: boolean;
    /**
     * 
     * @type {Date}
     * @memberof NotificationResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof NotificationResponse
     */
    updatedAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum NotificationResponseSeverityEnum {
    Info = 'INFO',
    Success = 'SUCCESS',
    Warn = 'WARN',
    Error = 'ERROR'
}

export function NotificationResponseFromJSON(json: any): NotificationResponse {
    return NotificationResponseFromJSONTyped(json, false);
}

export function NotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'title': json['title'],
        'message': json['message'],
        'severity': json['severity'],
        'seen': json['seen'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function NotificationResponseToJSON(value?: NotificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'tenantId': value.tenantId,
        'title': value.title,
        'message': value.message,
        'severity': value.severity,
        'seen': value.seen,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


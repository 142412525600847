/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeploymentLabel
 */
export interface DeploymentLabel {
    /**
     * 
     * @type {string}
     * @memberof DeploymentLabel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentLabel
     */
    value: string;
}

export function DeploymentLabelFromJSON(json: any): DeploymentLabel {
    return DeploymentLabelFromJSONTyped(json, false);
}

export function DeploymentLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
    };
}

export function DeploymentLabelToJSON(value?: DeploymentLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentReachabilitySecurity,
    DeploymentReachabilitySecurityFromJSON,
    DeploymentReachabilitySecurityFromJSONTyped,
    DeploymentReachabilitySecurityToJSON,
} from './DeploymentReachabilitySecurity';

/**
 * 
 * @export
 * @interface DeploymentReachability
 */
export interface DeploymentReachability {
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentReachability
     */
    isPublic: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeploymentReachability
     */
    domain?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentReachability
     */
    domainUseTls?: boolean;
    /**
     * 
     * @type {DeploymentReachabilitySecurity}
     * @memberof DeploymentReachability
     */
    security?: DeploymentReachabilitySecurity;
}

export function DeploymentReachabilityFromJSON(json: any): DeploymentReachability {
    return DeploymentReachabilityFromJSONTyped(json, false);
}

export function DeploymentReachabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentReachability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPublic': json['isPublic'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'domainUseTls': !exists(json, 'domainUseTls') ? undefined : json['domainUseTls'],
        'security': !exists(json, 'security') ? undefined : DeploymentReachabilitySecurityFromJSON(json['security']),
    };
}

export function DeploymentReachabilityToJSON(value?: DeploymentReachability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isPublic': value.isPublic,
        'domain': value.domain,
        'domainUseTls': value.domainUseTls,
        'security': DeploymentReachabilitySecurityToJSON(value.security),
    };
}


import {Page, Pageable, PageResponse, UUID} from "../adapters/interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {TenantResponse} from "../generated/models/TenantResponse";
import {UpdateTenantRequest} from "../generated/models/UpdateTenantRequest";
import {CreateTenantRequest} from "../generated/models/CreateTenantRequest";
import {TenantAdapter} from "../adapters/TenantAdapter";
import {tenants} from "./demoData";

export class TenantAdapterDemo extends TenantAdapter {

    create(login: LoginHolder | null, elements: any[]): Promise<TenantResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateTenantRequest): Promise<TenantResponse> {
        return Promise.reject("Not implemented")
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<TenantResponse>> {
        return Promise.resolve(new PageResponse<TenantResponse>(request.number || 0, request.size || 0, Object.keys(tenants).length, Object.values(tenants).map(t => {
            return {id: t.id, alias: t.alias, name: t.name, createdAt: new Date(0), updatedAt: new Date(0)}
        })))
    }

    get(login: LoginHolder | null, id: UUID): Promise<TenantResponse | null> {
        return Promise.reject("Not implemented")
    }

    update(login: LoginHolder | null, elements: UpdateTenantRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateTenantRequest): Promise<void> {
        return Promise.reject("Not implemented")
    }

}

import {UiConfigAdapter} from "../adapters/UiConfigAdapter";

export class UiConfigAdapterDemo extends UiConfigAdapter {

    config(): Promise<any> {
        return Promise.resolve({
            "version": "demo",
            "logging-enabled": "true",
            "root-url": "http://demo.aios.dev.s3-website.eu-central-1.amazonaws.com",
            "open-api-spec-url": "http://demo.aios.dev.s3-website.eu-central-1.amazonaws.com/api.yaml",
            "keycloak-enabled": "false",
            "keycloak-openid-connect-url": "",
            "mqtt-enabled": "false",
            "mqtt-url": "",
            "grafana-enabled": "false",
            "grafana-url": "",
        })
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserNameResponse
 */
export interface UserNameResponse {
    /**
     * 
     * @type {string}
     * @memberof UserNameResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserNameResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserNameResponse
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNameResponse
     */
    lastname?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserNameResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserNameResponse
     */
    updatedAt: Date;
}

export function UserNameResponseFromJSON(json: any): UserNameResponse {
    return UserNameResponseFromJSONTyped(json, false);
}

export function UserNameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function UserNameResponseToJSON(value?: UserNameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


import React, {createContext, useState} from "react";
import {getLocalStorageString} from "../../misc/misc";

export interface ThemeTypeContextState {
    themeType: 'light' | 'dark' | null
    setThemeType: (type: 'light' | 'dark') => void
}

const initialState: ThemeTypeContextState = {
    themeType: null, setThemeType: () => {
    }
}

export const PREFERRED_THEME_STORAGE_KEY = 'aiosPreferredTheme'
export const ThemeTypeContext = createContext<ThemeTypeContextState>(initialState)

const ThemeTypeProvider: React.FC = ({children}) => {
    const [themeType, setThemeType] = useState<'light' | 'dark' | null>(getLocalStorageString(PREFERRED_THEME_STORAGE_KEY) as 'light' | 'dark' | null)
    const defineThemeType = (type: 'light' | 'dark') => {
        setThemeType(type)
        localStorage.setItem(PREFERRED_THEME_STORAGE_KEY, type)
    }
    return (
            <ThemeTypeContext.Provider value={{themeType, setThemeType: defineThemeType}}>
                {children}
            </ThemeTypeContext.Provider>
    )
}

export default ThemeTypeProvider

/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentLabel,
    DeploymentLabelFromJSON,
    DeploymentLabelFromJSONTyped,
    DeploymentLabelToJSON,
} from './DeploymentLabel';

/**
 * 
 * @export
 * @interface DeploymentResources
 */
export interface DeploymentResources {
    /**
     * 
     * @type {number}
     * @memberof DeploymentResources
     */
    replicas: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentResources
     */
    cpuMinInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentResources
     */
    cpuMaxInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentResources
     */
    memoryMinInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentResources
     */
    memoryMaxInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentResources
     */
    gpu: number;
    /**
     * 
     * @type {Array<DeploymentLabel>}
     * @memberof DeploymentResources
     */
    runRestrictions?: Array<DeploymentLabel>;
}

export function DeploymentResourcesFromJSON(json: any): DeploymentResources {
    return DeploymentResourcesFromJSONTyped(json, false);
}

export function DeploymentResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentResources {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'replicas': json['replicas'],
        'cpuMinInMilliseconds': json['cpuMinInMilliseconds'],
        'cpuMaxInMilliseconds': json['cpuMaxInMilliseconds'],
        'memoryMinInMegaBytes': json['memoryMinInMegaBytes'],
        'memoryMaxInMegaBytes': json['memoryMaxInMegaBytes'],
        'gpu': json['gpu'],
        'runRestrictions': !exists(json, 'runRestrictions') ? undefined : ((json['runRestrictions'] as Array<any>).map(DeploymentLabelFromJSON)),
    };
}

export function DeploymentResourcesToJSON(value?: DeploymentResources | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'replicas': value.replicas,
        'cpuMinInMilliseconds': value.cpuMinInMilliseconds,
        'cpuMaxInMilliseconds': value.cpuMaxInMilliseconds,
        'memoryMinInMegaBytes': value.memoryMinInMegaBytes,
        'memoryMaxInMegaBytes': value.memoryMaxInMegaBytes,
        'gpu': value.gpu,
        'runRestrictions': value.runRestrictions === undefined ? undefined : ((value.runRestrictions as Array<any>).map(DeploymentLabelToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TenantMetricsRangeResponseMetrics,
    TenantMetricsRangeResponseMetricsFromJSON,
    TenantMetricsRangeResponseMetricsFromJSONTyped,
    TenantMetricsRangeResponseMetricsToJSON,
} from './TenantMetricsRangeResponseMetrics';

/**
 * 
 * @export
 * @interface TenantMetricsRangeResponse
 */
export interface TenantMetricsRangeResponse {
    /**
     * 
     * @type {Array<TenantMetricsRangeResponseMetrics>}
     * @memberof TenantMetricsRangeResponse
     */
    metrics: Array<TenantMetricsRangeResponseMetrics>;
}

export function TenantMetricsRangeResponseFromJSON(json: any): TenantMetricsRangeResponse {
    return TenantMetricsRangeResponseFromJSONTyped(json, false);
}

export function TenantMetricsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantMetricsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': ((json['metrics'] as Array<any>).map(TenantMetricsRangeResponseMetricsFromJSON)),
    };
}

export function TenantMetricsRangeResponseToJSON(value?: TenantMetricsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': ((value.metrics as Array<any>).map(TenantMetricsRangeResponseMetricsToJSON)),
    };
}


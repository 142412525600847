/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeploymentStatusLastFailure
 */
export interface DeploymentStatusLastFailure {
    /**
     * 
     * @type {number}
     * @memberof DeploymentStatusLastFailure
     */
    exitCode?: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentStatusLastFailure
     */
    note?: string;
}

export function DeploymentStatusLastFailureFromJSON(json: any): DeploymentStatusLastFailure {
    return DeploymentStatusLastFailureFromJSONTyped(json, false);
}

export function DeploymentStatusLastFailureFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentStatusLastFailure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exitCode': !exists(json, 'exitCode') ? undefined : json['exitCode'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function DeploymentStatusLastFailureToJSON(value?: DeploymentStatusLastFailure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exitCode': value.exitCode,
        'note': value.note,
    };
}


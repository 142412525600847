/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentSpecification,
    DeploymentSpecificationFromJSON,
    DeploymentSpecificationFromJSONTyped,
    DeploymentSpecificationToJSON,
} from './DeploymentSpecification';

/**
 * 
 * @export
 * @interface DeploymentResponse
 */
export interface DeploymentResponse {
    /**
     * 
     * @type {string}
     * @memberof DeploymentResponse
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof DeploymentResponse
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentResponse
     */
    tenantId: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentResponse
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeploymentResponse
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentResponse
     */
    description?: string;
    /**
     * 
     * @type {DeploymentSpecification}
     * @memberof DeploymentResponse
     */
    specification: DeploymentSpecification;
    /**
     * 
     * @type {string}
     * @memberof DeploymentResponse
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof DeploymentResponse
     */
    createdAt?: Date;
}

export function DeploymentResponseFromJSON(json: any): DeploymentResponse {
    return DeploymentResponseFromJSONTyped(json, false);
}

export function DeploymentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'],
        'tenantId': json['tenantId'],
        'enabled': json['enabled'],
        'alias': json['alias'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'specification': DeploymentSpecificationFromJSON(json['specification']),
        'userId': json['userId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function DeploymentResponseToJSON(value?: DeploymentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'tenantId': value.tenantId,
        'enabled': value.enabled,
        'alias': value.alias,
        'name': value.name,
        'description': value.description,
        'specification': DeploymentSpecificationToJSON(value.specification),
        'userId': value.userId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


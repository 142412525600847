/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CpuResourceMetricsResponse
 */
export interface CpuResourceMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof CpuResourceMetricsResponse
     */
    requestedInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof CpuResourceMetricsResponse
     */
    limitedInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof CpuResourceMetricsResponse
     */
    totalInMilliseconds: number;
}

export function CpuResourceMetricsResponseFromJSON(json: any): CpuResourceMetricsResponse {
    return CpuResourceMetricsResponseFromJSONTyped(json, false);
}

export function CpuResourceMetricsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CpuResourceMetricsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestedInMilliseconds': json['requestedInMilliseconds'],
        'limitedInMilliseconds': json['limitedInMilliseconds'],
        'totalInMilliseconds': json['totalInMilliseconds'],
    };
}

export function CpuResourceMetricsResponseToJSON(value?: CpuResourceMetricsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestedInMilliseconds': value.requestedInMilliseconds,
        'limitedInMilliseconds': value.limitedInMilliseconds,
        'totalInMilliseconds': value.totalInMilliseconds,
    };
}


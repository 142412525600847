/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentFile,
    DeploymentFileFromJSON,
    DeploymentFileFromJSONTyped,
    DeploymentFileToJSON,
} from './DeploymentFile';
import {
    DeploymentVariable,
    DeploymentVariableFromJSON,
    DeploymentVariableFromJSONTyped,
    DeploymentVariableToJSON,
} from './DeploymentVariable';

/**
 * 
 * @export
 * @interface DeploymentEnvironment
 */
export interface DeploymentEnvironment {
    /**
     * 
     * @type {Array<DeploymentVariable>}
     * @memberof DeploymentEnvironment
     */
    variables: Array<DeploymentVariable>;
    /**
     * 
     * @type {Array<DeploymentFile>}
     * @memberof DeploymentEnvironment
     */
    configFiles: Array<DeploymentFile>;
}

export function DeploymentEnvironmentFromJSON(json: any): DeploymentEnvironment {
    return DeploymentEnvironmentFromJSONTyped(json, false);
}

export function DeploymentEnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentEnvironment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'variables': ((json['variables'] as Array<any>).map(DeploymentVariableFromJSON)),
        'configFiles': ((json['configFiles'] as Array<any>).map(DeploymentFileFromJSON)),
    };
}

export function DeploymentEnvironmentToJSON(value?: DeploymentEnvironment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'variables': ((value.variables as Array<any>).map(DeploymentVariableToJSON)),
        'configFiles': ((value.configFiles as Array<any>).map(DeploymentFileToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeploymentStatusLastFailure,
    DeploymentStatusLastFailureFromJSON,
    DeploymentStatusLastFailureFromJSONTyped,
    DeploymentStatusLastFailureToJSON,
} from './DeploymentStatusLastFailure';

/**
 * 
 * @export
 * @interface DeploymentStatus
 */
export interface DeploymentStatus {
    /**
     * 
     * @type {string}
     * @memberof DeploymentStatus
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentStatus
     */
    running?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentStatus
     */
    ready?: boolean;
    /**
     * 
     * @type {DeploymentStatusLastFailure}
     * @memberof DeploymentStatus
     */
    lastFailure?: DeploymentStatusLastFailure;
}

export function DeploymentStatusFromJSON(json: any): DeploymentStatus {
    return DeploymentStatusFromJSONTyped(json, false);
}

export function DeploymentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'running': !exists(json, 'running') ? undefined : json['running'],
        'ready': !exists(json, 'ready') ? undefined : json['ready'],
        'lastFailure': !exists(json, 'lastFailure') ? undefined : DeploymentStatusLastFailureFromJSON(json['lastFailure']),
    };
}

export function DeploymentStatusToJSON(value?: DeploymentStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'running': value.running,
        'ready': value.ready,
        'lastFailure': DeploymentStatusLastFailureToJSON(value.lastFailure),
    };
}

